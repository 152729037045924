import React from "react";
import Layout from "../common/Layout";

import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const Colop = ({ data }) => {
  return (
    <>
      <SEO title="Colop" />

      <Layout>
        <PageBanner
          pageTitle=" COLOP E-MARK SECURE"
          homePageUrl="/"
          homePageText="HOME"
          activePageText=" COLOP E-MARK SECURE"
          bgImgClass="colop"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className=" theme-gradient capitalText">
                    THE COLOP E-MARK SECURE
                  </h3>
                  <p data-aos="fade-right">
                    The COLOP e-mark secure device is a first-of-its-kind
                    personal security token capable of printing encrypted unique
                    keys as QR-codes directly on any kind of documents.
                  </p>

                  <p data-aos="fade-right">
                    In digital documents, the QR-code is printed on any piece of
                    paper to be scanned and validated so that the authentication
                    of the digital document can be finalized.
                  </p>

                  <h4 className="capitalText">
                    COLOP E-MARK SECURE HIGHLIGHTS
                  </h4>
                  <ul data-aos="fade-left">
                    <li>
                      Fully digital security solution with a 2-way bridge for
                      paper documents
                    </li>
                    <li>Based on Blockchain technology</li>
                    <li>
                      The unique key is encrypted and stored on the document
                      itself only
                    </li>
                    <li>
                      Digitalization of paper documents takes place
                      simultaneously with the authentication process
                    </li>
                    <li>
                      Digital documents can be printed keeping the same high
                      level of security
                    </li>
                  </ul>
                  <h4 className="capitalText">IOS VERIFICATION APP</h4>
                  <p data-aos="fade-right">
                    Can you trust your eyes? With the COLOP e-mark secure
                    Verification App for iPhones you can. All this information
                    will be made available directly at your fingertips:
                  </p>
                  <ul data-aos="fade-left">
                    <li>Authorized Organization</li>
                    <li>Stamped by</li>
                    <li>Stamp Device ID-No.</li>
                    <li>Stamped at Location</li>
                    <li>Stamp date, time, number</li>
                    <li>Stamp notes</li>
                    <li>Document scan</li>
                  </ul>
                  <h4 className="capitalText">IOS AUTHENTICATION APP</h4>
                  <p data-aos="fade-right">
                    Authenticate and simultaneously digitize physical documents
                    into the blockchain within seconds using the COLOP e-mark
                    secure Authentication App for iPhones!
                  </p>

                  <h4 className="capitalText">ANDROID VERIFICATION APP</h4>
                  <p data-aos="fade-right">
                    Feel confident about the authenticity of an important
                    document, regardless of if it is on your screen or printed
                    on paper. The COLOP Verification App for Android will help
                    you analyze a QR-code and its context within seconds. (No
                    need to install it. Available to “Try now” as an Instant
                    App).
                  </p>
                  <p data-aos="fade-right">
                    Retrieve all the following details from a blockchain
                    database:
                  </p>
                  <ul data-aos="fade-left">
                    <li> Authorized Organization</li>
                    <li>Stamped by</li>
                    <li>Stamp Device ID-No.</li>
                    <li>Stamped at Location</li>
                    <li>Stamp date, time, number</li>
                    <li>Stamp notes</li>
                    <li>Document scan</li>
                  </ul>

                  <h4 className="capitalText">ANDROID AUTHENTICATION APP</h4>
                  <p data-aos="fade-right">
                    Authenticate and simultaneously digitize physical documents
                    into the Blockchain! Available on Mid-February 2023 on
                    Google Play.
                  </p>

                  <h4 className="capitalText">DESKTOP AUTHENTICATION APP</h4>
                  <p data-aos="fade-right">
                    Authenticate digital documents in different formats (Word,
                    PDF, Images, etc.) and in all platforms (Windows, Linux,
                    MAC) Available for customers that have bought the COLOP
                    e-mark secure device.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Colop;
