import Layout from "../common/Layout";
import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const Consultation = () => {
  return (
    <>
    <SEO title={'CONSULTATION'}/>
      <Layout>
        <PageBanner
          pageTitle="CONSULTATION"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="CONSULTATION"
          bgImgClass="consultation"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className="theme-gradient" data-aos="fade-right">
                    CONSULTATION
                  </h3>
                  <p data-aos="fade-right">
                    Welcome to METANOW, your partner in digital transformation!
                    Our team of experts is here to help you optimize your
                    business processes, streamline your operations, and enhance
                    your customer experience using cutting-edge technology and
                    innovative solutions.
                  </p>

                  <p data-aos="fade-right">
                    We understand that each business has unique challenges and
                    opportunities, which is why we provide tailored services
                    that meet your specific needs. Our digital transformation
                    solutions range from AI-powered tools to cloud-based
                    platforms, and everything in between.
                  </p>
                  <h4 className="capitalText" data-aos="fade-right">
                    Our expertise includes
                  </h4>
                  <ul data-aos="fade-left">
                    <li>Data management and analytics</li>
                    <li>Cloud computing and storage</li>
                    <li>AI and machine learning</li>
                    <li>Robotic process automation (RPA)</li>
                    <li>Cybersecurity and risk management</li>
                    <li>Digital marketing and customer engagement</li>
                  </ul>

                  <p data-aos="fade-right">
                    Our approach is based on a thorough analysis of your
                    business objectives, your current technology infrastructure,
                    and your customer requirements. We work closely with you to
                    identify areas for improvement and develop a roadmap for
                    success.
                  </p>

                  <p data-aos="fade-left">
                    At Metanow, we also offer a range of digital products that
                    can help you optimize your operations and enhance your
                    customer experience.
                  </p>
                  <h4 className="capitalText" data-aos="fade-left">
                    Our products includes
                  </h4>
                  <ul data-aos="fade-right">
                    <li>Augmented reality glasses</li>
                    <li>5G smartphones</li>
                    <li>Secure authenticator app</li>
                    <li>Virtual reality software</li>
                    <li>AI-powered chatbots</li>
                    <li>And more!</li>
                  </ul>

                  <p data-aos="fade-left">
                    Our services and products are complemented by our dedicated
                    customer support team, who are available to provide
                    assistance and answer any questions you may have. We are
                    committed to delivering outstanding customer service and
                    ensuring your satisfaction with our solutions.
                  </p>

                  <p data-aos="fade-left">
                    Whether you're looking to implement a new system, optimize
                    your existing technology infrastructure, or enhance your
                    customer experience, we're here to help. Contact us today to
                    schedule a consultation and see how we can help your
                    business thrive in the digital age.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Consultation;
