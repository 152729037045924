import React from 'react';
import SEO from "../../common/SEO";

import SectionTitle from "../sectionTitle/SectionTitle";
import VideoOne from "./VideoOne";
import ScrollAnimation from 'react-animate-on-scroll';

const Video = () => {
    return (
        <>
            <SEO title="Video Section|| Explore Tech With Metanow" />
    
              
                  <ScrollAnimation  animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}>
                <div >
                    {/* Start Elements Area  */}
                    <div className="rwt-video-popup-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Popup Video"
                                            title = "Explore The Technology With Metanow"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <VideoOne />
                            {/* <VideoTwo/> */}
                        </div>
                    </div>
                    {/* End Elements Area  */}
                </div>
                </ScrollAnimation>
            
        </>
    )
}
export default Video;