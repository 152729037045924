import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const PageBanner=(props)=>{
    

        let { pageTitle, homePageUrl, homePageText, activePageText, bgImgClass } = props;

        return (
            <div className={`page-title-area ${bgImgClass}`}>
                <div className="container">
                    <div className="page-title-content">
                        <h2 >{pageTitle}</h2>
                        <ul>
                            <li>
                                <Link href={homePageUrl}>
                                    {homePageText}
                                </Link>
                            </li>
                            <li className='activePageTxt'>{activePageText}</li>
                        </ul>
                    </div>
                </div>

                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        );
    }


export default PageBanner;