import SectionTitle from "../sectionTitle/SectionTitle";
import ContactForm from "../contact/ContactForm";
import Layout from "../../common/Layout";
import PageBanner from "../../common/page-banner/PageBanner";
import SEO from "../../common/SEO";

const GetAQuote = () => {


  
  return (
    <>
    <SEO title={'GET A QUOTE'}/>
    <Layout>
     <PageBanner
          pageTitle="GET A QUOTE"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="GET A QUOTE"
          bgImgClass="quote"
        />
    
    <div className="rwt-portfolio-details rn-section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="details-list">
                <div className="thumbnail alignwide">
                  {/* <img
                    className="radius w-100"
                    src={getAQuoteTwo}
                    alt="Corporate Image"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt--40">
            <SectionTitle
              textAlign="text-center"
              radiusRounded=""
              subtitle="Get A Quote"
              title="Get A Quote "
              description=""
            />
          </div>
          <div className="mt--40">
          <ContactForm formStyle="contact-form-1" />

          </div>
        </div>
      </div>
    </div>
    </Layout>
    </>
    );
};

export default GetAQuote;
