import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "react-router-dom"
import SectionTitle from "../sectionTitle/SectionTitle"
const TopSolutions = () => {

    return (
        <ScrollAnimation animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
            <div className="top-featured-area rwt-progressbar-area rn-section-gap" >
                <div className="container">
                    <div className="section-title theme-gradient with-underline-text">


                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            title="OUR AI BUSINESS SOLUTIONS"

                        />

                    </div>
                    {/* <p className="topSolDesc">Lorem ipsum dolor sit amet consectetur adipiscing elit. A ac, ut eget pellentesque nulla viverr.</p> */}

                    <div className="row justify-content-center pt-5" data-aos="fade-up" >
                        <div className="col-lg-3 col-sm-6">
                            <div className="top-featured-card text-center">
                                <div className="image-icon">
                                    <img src="/images/icons/icon1.png" alt="icon" />
                                </div>
                                <h3 className="capitalText ">
                                    
                                        Natural language processing
                                    
                                </h3>
                                <Link className=" learn-btn" to={'/natural-language-processing'}>
                                    Learn More
                                </Link>
                            </div>



                        </div>



                        <div className="col-lg-3 col-sm-6">
                            <div className="top-featured-card text-center">
                                <div className="image-icon">
                                    <img src="/images/icons/icon2.png" alt="icon" />
                                </div>
                                <h3 className="capitalText ">
                                   
                                        Predictive<br/> analytics
                                   
                                </h3>
                                <Link className="learn-btn" to={'/predictive-analysis'}>
                                    Learn More
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="top-featured-card text-center">
                                <div className="image-icon">
                                    <img src="/images/icons/icon3.png" alt="icon" />
                                </div>
                                <h3 className="capitalText ">
                                  
                                        Computer<br/> vision
                                    
                                </h3>
                                <Link className="learn-btn" to="/computer-vision">
                                    Learn More
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="top-featured-card text-center">
                                <div className="image-icon">
                                    <img src="/images/icons/icon4.png" alt="icon" />
                                </div>
                                <h3 className="capitalText ">
                                  
                                        Intelligent<br/> automation
                                   
                                </h3>
                                <Link className="learn-btn" to={'/intelligent-automation'}>
                                    Learn More
                                </Link>
                            </div>
                        </div>

                    
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    )
}

export default TopSolutions