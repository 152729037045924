import Layout from "../../common/Layout";
import PageBanner from "../../common/page-banner/PageBanner";
import SEO from "../../common/SEO";
import ctoImg from "./cto.png";

const CTO = () => {
  return (
    <>
    <SEO title='CHIEF TECHNICAL OFFICER'/>
      <Layout>
        <PageBanner
          pageTitle="CHIEF TECHNICAL OFFICER"
          homePageUrl="/"
          homePageText="Home"
          activePageText="CHIEF TECHNICAL OFFICER"
          bgImgClass="item-bgCto"
        />
        <div className="services-details-area ptb-110">
          <div className="container">
            <div className="services-details-overview">
              <div
                className="services-details-desc mb-30"
                data-aos="fade-right"
              >
                <h2 className="capitalText theme-gradient ctoName">
                  Mohamad El Jachi
                </h2>
                <h4 className="about-subtitle capitalText ">
                  Chief Technical Officer
                </h4>

                <p>
                  Luck can play a vital role in achieving success. However,
                  relying solely on luck is not a viable strategy as it may
                  never come to fruition. Instead, it's important to take action
                  and embark on a journey towards your goals. During the
                  journey, luck may present itself as an opportunity that you
                  can seize to further your success.
                </p>
                <p>
                  Meet Mohamad, a seasoned technical expert with a passion for
                  digital transformation. With a proven track record of
                  delivering exceptional results, he brings a wealth of
                  experience to the table. His expertise in Artificial
                  Intelligence, data analysis, and IT infrastructure is
                  unmatched, having accumulated over 8 years of industry
                  experience. Mohamad is a visionary leader who strives to be
                  the most reliable source in his field. He continuously expands
                  his knowledge to stay ahead of the latest technologies and
                  trends in digital transformation, always with a
                  customer-centric approach. With Moe at the helm of the
                  technical team, clients can be confident that their digital
                  transformation efforts are in expert hands.
                </p>
                
              </div>

              <div className="services-details-image" data-aos="fade-left">
                <img
                  src={ctoImg}
                  className="aboutImgOne"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CTO;
