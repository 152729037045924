import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
const AISolutions = () => {
  return (
    <>
    <SEO title='AI SOLUTIONS'/>
    <div className="ai-services-area ai-sol with-linear-gradient-color pt-100 pb-100">
      <div className="container" data-aos="fade-up">
        <div className="section-title with-underline-text">
          <h2 className="text-center capitalText theme-gradient">
            the Incredible Benefits of Internet of Things
          </h2>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon">
                <img src="/images/aisolutions/icon1.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">Increased Efficiency</h3>
              <p>
                By connecting devices to the internet, it is possible to
                automate processes, which can lead to improved efficiency and
                productivity.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-two">
                <img src="/images/aisolutions/icon2.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">
                <Link href="">Improved Safety </Link>
              </h3>
              <p>
                IoT can help monitor the environment and alert people of
                potential hazards.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-three">
                <img src="/images/aisolutions/icon3.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">
                Enhanced Quality of Life
              </h3>
              <p>
                IoT can provide people with more control over their environment
                and make everyday activities easier.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-four">
                <img src="/images/aisolutions/icon4.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">Cost Savings</h3>
              <p>
                IoT can reduce costs by enabling more efficient operations and
                eliminating the need of human labor.
              </p>
              <p> </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-five">
                <img src="/images/aisolutions/icon5.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">Increased Connectivity</h3>
              <p>
                IoT can facilitate increased connectivity among devices, making
                it easier to access data and services.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-six">
                <img src="/images/aisolutions/icon6.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">More Personalization</h3>
              <p>
                IoT can enable devices to be customized to the user's
                preferences in order to provide a better user experience.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-six">
                <img src="/images/aisolutions/icon7.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">Better Decision Making</h3>
              <p>
                IoT can provide real-time data which can be used to make better
                decisions.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-six">
                <img src="/images/aisolutions/icon8.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">
                Improved Customer Experience
              </h3>
              <p>
                IoT can be used to provide customers with a better experience by
                providing personalized services and products.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-card">
              <div className="image-icon bg-six">
                <img src="/images/aisolutions/icon9.png" alt="icon" />
              </div>
              <h3 className="capitalText aiHeading">More Opportunities</h3>
              <p>
                IoT can create new opportunities for businesses to explore new
                markets and services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ai-services-shape">
        <img
          className="aiShapeCircle"
          src="/images/aisolutions/calltoaction.png"
          alt="imageCallToAction"
        />
      </div>
    </div>
    </>
  );
};

export default AISolutions;
