import PageBanner from "../../common/page-banner/PageBanner"
import Layout from "../../common/Layout"
import SEO from "../../common/SEO"



const TermConditions=()=>{


    return(
        <>
        <SEO title='TERMS & CONDITIONS'/>
      <Layout>
        <PageBanner
          pageTitle="TERMS & CONDITIONS"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="TERMS & CONDITIONS (Last updated: 19 – 02 - 2023)"
        />

        <div className="text-container pt-100"><div className="container"><p>Welcome to METANOW! These Terms and Conditions govern your use of our website and services. By using our website, you agree to be bound by these Terms and Conditions.</p></div></div>
        <div className="text-container pt-60 pb-100 ">
          <div className="container">
            <h4>DESCRIPTION OF SERVICES</h4>

            <p>
            We offer digital transformation solutions through our AI tools and technology, as well as exclusive products and integrations with various software platforms. Our services include integration, consulting, professional services, turnkey solutions, and a marketplace that brings together suppliers, resellers, distributors, and customers.
            </p>

            <h4>FEE AND PAYMENT</h4>
            <p>
            Some of our services are provided on a paid basis. Fees for these services are clearly stated on our website or provided directly to customers upon request. Payment terms and conditions may vary based on the service being provided. Customers are responsible for paying all applicable fees and taxes.
            </p>

          

            <h4>USE OF OUR SERVICES</h4>
            <p>
            By using our services, you agree to comply with these Terms and Conditions, as well as any additional terms and conditions specific to the service being used. You agree not to use our services for any illegal or unauthorized purpose. You also agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of our services.            </p>

            <h4>INTELLECTUAL PROPERTY</h4>

            <p>
            All intellectual property rights related to our website and services, including trademarks, copyrights, and patents, are the property of METANOW. You may not use any of our intellectual property without our prior written consent.            </p>
            <h4>CONFIDENTIALITY</h4>
            <p>We take confidentiality seriously and will use reasonable efforts to protect your confidential information. We may use and disclose your confidential information as necessary to provide our services but will not share your confidential information with third parties unless required by law.</p>

            <h4>DISCLAIMER OF LIABILITY</h4>
            <p>Our services are provided on an "as is" basis, and we make no warranties or representations regarding the accuracy, completeness, or reliability of our services. We disclaim all liability for any damages or losses that may result from using our services, including any direct, indirect, incidental, or consequential damages.</p>
            <h4>TERMINATION</h4>
            <p>We may terminate your access to our services at any time for any reason, including for breach of these Terms and Conditions. Upon termination, you must immediately cease using our services.</p>

<h4>GOVERNING LAW AND JURISDICTION</h4>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of Dubai, United Arab Emirates. Any legal action arising from these Terms and Conditions will be resolved in the courts of Dubai, United Arab Emirates.</p>


            <p>Thank you for choosing METANOW as your digital transformation partner. If you have any questions or concerns about these Terms and Conditions, please contact us through our website.</p>
          </div>
        </div>
      </Layout>
    </>
    )
}


export default TermConditions