import Layout from "../../common/Layout";
import PageBanner from "../../common/page-banner/PageBanner";
import AIContent from "./AIContent";
import SEO from "../../common/SEO";

const AITechnology = () => {
  return (
    <>
    <SEO title='AI TECHNOLOGY'/>
      <Layout>
        <PageBanner
          pageTitle="AI TECHNOLOGY"
          homePageUrl="/"
          homePageText="Home"
          activePageText="AI TECH"
          bgImgClass="item-bgAI"
        />

        <AIContent />
      </Layout>
    </>
  );
};

export default AITechnology;
