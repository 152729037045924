import SEO from "../../common/SEO";

const IotContent = () => {
  return (
    <>
      <SEO title="INTERNET OF THINGS" />
      <section className="about-area ptb-110">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-12" data-aos="fade-right">
              <div className="about-image">
                <img src="/images/about/about2.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12" data-aos="fade-left">
              <div className="about-content">
                <h2 className="theme-gradient">
                  INTERNET OF THINGS FUTURE MARKET
                </h2>
                <p>
                  The Internet of Things (IoT) market is predicted to grow by
                  18% in 2023, with over 14.4 billion active connections.
                  Healthcare is a prime area for IoT technology, with the
                  IoT-enabled health device market expected to reach $267
                  billion by 2023. The industry is rapidly adopting IoT
                  technology across various sectors, with emerging trends such
                  as blockchain, smart cities, traffic management, AI
                  applications, and digital twins.
                </p>{" "}
                <p>
                  Other industries that will experience significant IoT-driven
                  changes include traffic management, manufacturing,
                  transportation, and construction. The application of AI in IoT
                  is also gaining momentum, as organizations look to extract
                  more insights from the vast amounts of data generated by
                  connected devices. Overall, the IoT industry is witnessing
                  tremendous growth and innovation, with various emerging trends
                  promising to shape the future of technology and transform the
                  way we live and work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IotContent;
