import emailjs from "emailjs-com";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function ContactForm({ props, formStyle }) {
  const location = useLocation();

  const successToast = () => {
    toast.success(
      "Your Message has been successfully sent. We will get back to you soon. ",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };

  let templateId =
    location?.pathname === "/contact" ? "template_gqvb6bn" : "template_o95eibb";

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_eard45k", templateId, e.target, "2FOxLaMviOYclbtH8")
      .then(
        (result) => {
          console.log(result.text);
          successToast();
        },
        (error) => {
          console.log(error.text);
          toast.error(error.text, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
    e.target.reset();
  };

  return (
    <form className={`contact-form--1`} action="" onSubmit={sendEmail}>
      <ToastContainer
        draggable={false}
        transition={Zoom}
        autoClose={8000}
        theme="colored"
      />

      <div class="form-group position-relative">
        <label>
        FULL NAME <span className="required">*</span>
        </label>
        <input name="con_name" type="text" />
        <span class="focus-border"></span>
      </div>

      <div class="form-group position-relative">
        <label>
        EMAIL ADDRESS <span className="required">*</span>
        </label>
        <input name="con_name" type="text" />
        <span class="focus-border"></span>
      </div>
      <div class="form-group position-relative">
        <label>
          Contact Number
        </label>
        <input  type="number" />
        <span class="focus-border"></span>
      </div>

      <div class="form-group position-relative">
        <label>Subject <span className="required">*</span></label>
        <input name="con_email" type="email" />
        <span class="focus-border"></span>
      </div>

      <div class="form-group position-relative">
        <label>
        Description <span className="required">*</span>
        </label>
        <textarea name="message" placeholder="Your Message" required></textarea>

        <span class="focus-border"></span>
      </div>

      <div >
        <Link className={`btn-default sendBtn w-100`} to="">
        Send
        </Link>
      </div>
    </form>
  );
}
export default ContactForm;
