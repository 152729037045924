import Layout from "../common/Layout";
import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const TelecommunicationServices = () => {
  return (
    <>
    <SEO title={'TELECOMMUNICATION'}/>
    <Layout>
      <PageBanner
        pageTitle="TELECOMMUNICATION "
        homePageUrl="/"
        homePageText="HOME"
        activePageText="TELECOMMUNICATION "
        bgImgClass="telecom"
      />

      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <h3 className="theme-gradient">TELECOMMUNICATION </h3>

                <p data-aos="fade-right">
                  Stay connected with our comprehensive range of
                  telecommunication services. At METANOW, we understand the
                  importance of reliable and efficient communication, and we're
                  committed to delivering the best possible solution for your
                  business.
                </p>

                <h4 data-aos="fade-right" className="capitaltext">
                  OUR TELECOMMUNICATION SERVICES INCLUDE
                </h4>
                <ul data-aos="fade-left">
                  <li>Voice and data communication solutions</li>
                  <li>IP telephony and VolP services</li>
                  <li>Video conferencing and collaboration tools</li>
                  <li>Contact center solutions</li>
                  <li>Network and infrastructure design and implementation</li>
                </ul>

                <p data-aos="fade-right">
                  We use the latest technology and best practices to ensure that
                  your telecommunication infrastructure is secure, scalable, and
                  future proof. Whether you need to integrate with existing
                  systems or build a brand new solution, we're here to help.
                </p>

                <p data-aos="fade-left">
                  To learn more about our telecommunication services and how we
                  can help your business stay connected, contact us today. Our
                  team of experts will be happy to answer any questions you may
                  have and provide a personalized consultation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default TelecommunicationServices;
