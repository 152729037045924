// /* eslint-disable jsx-a11y/img-redundant-alt */
// import React, { useState } from "react";
// import FsLightbox from "fslightbox-react";
// import { FiPlay } from "react-icons/fi";
// import ModalVideo from 'react-modal-video'

// const PopupContent = {
//   popupLink: ["https://www.youtube.com/watch?v=fs-tFFisQjw"],
// };
// const PopupContentTwo = {
//   popupLinkTwo: ["https://www.youtube.com/watch?v=3WJF60jQjs8"],
// };
// const PopupContentThree = {
//   popupLinkThree: ["https://www.youtube.com/watch?v=uuLl0i3UAYU"],
// };
// const PopupContentFour = {
//   popupLinkFour: ["https://www.youtube.com/watch?v=TiZiKvPsHus"],
// };

// function VideoOne() {
//   const [toggler, setToggler] = useState(false);
//   const [togglerOne, setTogglerOne] = useState(false);
//   const [togglerTwo, setTogglerTwo] = useState(false);
//   const [togglerThree, setTogglerThree] = useState(false);
//   return (
//     <div className="row row--15">
//       <div className="col-lg-6 col-md-6 col-12 mt--30">
//         <div className="video-popup icon-center">
//           <div className="thumbnail">
//             <img
//               className="radius-small"
//               src={"./images/portfolio/1.png"}
//               alt="Corporate Image"
//             />
//           </div>
//           <div className="video-icon">
//             <button
//               className="btn-default rounded-player"
//               onClick={() => setToggler(!toggler)}
//             >
//               <span>
//                 <FiPlay />
//               </span>
//             </button>
//           </div>
//           <FsLightbox toggler={toggler} sources={PopupContent.popupLink} />
//         </div>
//       </div>

//       <div className="col-lg-6 col-md-6 col-12 mt--30">
//         <div className="video-popup icon-center">
//           <div className="thumbnail">
//             <img
//               className="radius-small"
//               src={"./images/portfolio/4.png"}
//               alt="Corporate Image"
//             />
//           </div>
//           <div className="video-icon">
//             <button
//               className="btn-default rounded-player"
//               onClick={() => setTogglerOne(!togglerOne)}
//             >
//               <span>
//                 <FiPlay />
//               </span>
//             </button>
//           </div>
//           <FsLightbox
//             toggler={togglerOne}
//             sources={PopupContentTwo.popupLinkTwo}
//           />
//         </div>
//       </div>

//       <div className="col-lg-6 col-md-6 col-12 mt--30">
//         <div className="video-popup icon-center">
//           <div className="thumbnail">
//             <img
//               className="radius-small"
//               src={"./images/portfolio/3.png"}
//               alt="Corporate Image"
//             />
//           </div>
//           <div className="video-icon">
//             <button
//               className="btn-default rounded-player"
//               onClick={() => setTogglerTwo(!togglerTwo)}
//             >
//               <span>
//                 <FiPlay />
//               </span>
//             </button>
//           </div>
//           <FsLightbox
//             toggler={togglerTwo}
//             sources={PopupContentThree.popupLinkThree}
//           />
//         </div>
//       </div>

//       <div className="col-lg-6 col-md-6 col-12 mt--30">
//         <div className="video-popup icon-center">
//           <div className="thumbnail">
//             <img
//               className="radius-small"
//               src={"./images/portfolio/2.png"}
//               alt="Corporate Image"
//             />
//           </div>
//           <div className="video-icon">
//             <button
//               className="btn-default rounded-player"
//               onClick={() => setTogglerThree(!togglerThree)}
//             >
//               <span>
//                 <FiPlay />
//               </span>
//             </button>
//           </div>
//           <FsLightbox
//             toggler={togglerThree}
//             sources={PopupContentFour.popupLinkFour} 
            
//           />
//         </div>
//       </div>


      
//     </div>
//   );
// }
// export default VideoOne;


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { FiPlay } from "react-icons/fi";
import ReactPlayer from 'react-player';
function VideoOne(props) {
  const [modalShow, setModalShow] = useState(false);
  const [videoUrl,setVideoUrl]=useState('')


   const PopupContent = {
   popupLink: ["https://www.youtube.com/watch?v=fs-tFFisQjw"],
 };
 const PopupContentTwo = {
   popupLinkTwo: ["https://www.youtube.com/watch?v=3WJF60jQjs8"],
 };
 const PopupContentThree = {
   popupLinkThree: ["https://www.youtube.com/watch?v=uuLl0i3UAYU"],
};
const PopupContentFour = {
   popupLinkFour: ["https://www.youtube.com/watch?v=TiZiKvPsHus"],
 };

  const handleClose=()=>{
    setModalShow(false)
  }

  return (
    <>
    <Modal
      show={modalShow}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header      style={{background:'#18191A'}}
>
        <Modal.Title className='theme-gradient' >
        TOGETHER WE CAN!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <ReactPlayer url={videoUrl} controls={false} width={'100%'}/>
      </Modal.Body>
      <Modal.Footer  style={{background:'#18191A'}}>
        <Button className='btn-default' onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>


    <div className="row row--15">
       <div className="col-lg-6 col-md-6 col-12 mt--30">
         <div className="video-popup icon-center">
           <div className="thumbnail">
             <img
               className="radius-small"
               src={"./images/portfolio/1.png"}
               alt="Corporate Image"
             />
           </div>
           <div className="video-icon">
             <button
               className="btn-default rounded-player"
               onClick={() => {
                setModalShow(true);
                setVideoUrl(PopupContent.popupLink)
              }}
             >
               <span>
                 <FiPlay />
               </span>
             </button>
           </div>
         </div>
       </div>

       <div className="col-lg-6 col-md-6 col-12 mt--30">
         <div className="video-popup icon-center">
           <div className="thumbnail">
             <img
               className="radius-small"
               src={"./images/portfolio/4.png"}
               alt="Corporate Image"
             />
           </div>
           <div className="video-icon">
             <button
               className="btn-default rounded-player"
               onClick={() => {
                setModalShow(true);
                setVideoUrl(PopupContentTwo.popupLinkTwo)
              }}
             >
               <span>
                 <FiPlay />
               </span>
             </button>
           </div>
         
         </div>
       </div>

       <div className="col-lg-6 col-md-6 col-12 mt--30">
         <div className="video-popup icon-center">
           <div className="thumbnail">
             <img
               className="radius-small"
               src={"./images/portfolio/3.png"}
               alt="Corporate Image"
             />
           </div>
           <div className="video-icon">
             <button
               className="btn-default rounded-player"
               onClick={() => {
                setModalShow(true);
                setVideoUrl(PopupContentThree.popupLinkThree)
              }}
             >
               <span>
                 <FiPlay />
               </span>
             </button>
           </div>
          
         </div>
       </div>

       <div className="col-lg-6 col-md-6 col-12 mt--30">
         <div className="video-popup icon-center">
           <div className="thumbnail">
             <img
               className="radius-small"
               src={"./images/portfolio/2.png"}
               alt="Corporate Image"
             />
           </div>
           <div className="video-icon">
             <button
               className="btn-default rounded-player"
               onClick={() => {
                setModalShow(true);
                setVideoUrl(PopupContentFour.popupLinkFour)
              }}
             >
               <span>
                 <FiPlay />
               </span>
             </button>
           </div>
        
         </div>
       </div>


      
     </div>
</>
  );
}

export default VideoOne
