import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect, useLayoutEffect } from "react";
import slideOne from "../../assets/sliderImages/1.webp";
import slideTwo from "../../assets/sliderImages/2.webp";
import slideThree from "../../assets/sliderImages/3.webp";
import slideFour from "../../assets/sliderImages/4.webp";
import slideFive from "../../assets/sliderImages/5.webp";
import slideSix from "../../assets/sliderImages/6.webp";
import slideSeven from "../../assets/sliderImages/7.webp";
import slideEight from "../../assets/sliderImages/8.webp";
import slideNine from "../../assets/sliderImages/9.webp";
import slideTen from "../../assets/sliderImages/10.webp";

import { Image } from "react-bootstrap";
import Aos from "aos";
import { TbH1 } from "react-icons/tb";

gsap.registerPlugin(ScrollTrigger);
const SliderSection = ({ textAlign, serviceStyle }) => {
  const wrapperRef = useRef();
  console.log(window.innerWidth)



  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    // if (mediaQuery.matches) {

      console.log('i am running')
      const sliderTopDiv = document.getElementsByClassName('sliderTopDiv')
      const slides = gsap.utils.toArray(".about");

      const tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: sliderTopDiv,
            start: "top top",
            end: "+=" + 100 * (slides.length - 1) + "5",
            pin:sliderTopDiv,
          
            scrub: 0.1,
            markers: false,
            
          
          },
        })
        .to(slides, {
          xPercent: -(100 * (slides.length - 1)),
          ease: "none",
        });

      const images = gsap.utils.toArray(".aboutImg");
      images.forEach((image, index) => {
        gsap.from(image, {
          opacity: 0.2,
          scale: 0.5,
          transformOrigin: "center center",
          ease: "none",
          scrollTrigger: {
            trigger: slides[index],
            containerAnimation: tl,
            start: "left 75%",
            end: "left 25%",
            //markers: { startColor: "fuchsia", endColor: "fuchsia" },
            scrub: true,
          }
        });
      });

      
      // txt.forEach((tx, index) => {
      //   gsap.fromTo(
      //     tx,
      //     { opacity: 0.2, transformOrigin: "center center" },
      //     {
      //       opacity: 1,
      //       scale: 1,
      //       duration: 0.7,
      //       ease: "none",
      //       scrollTrigger: {
      //         trigger: slides[index],
      //         containerAnimation: tl,
      //         start: "left 5%",
      //         end: "left 60%",
      //         markers: false,
      //         scrub: true,
      //       },
      //     },
      //     {
      //       opacity: 0,
      //       scale: 0.5,
      //       duration: 0.7,
      //       ease: "none",
      //       scrollTrigger: {
      //         trigger: slides[index + 1],
      //         containerAnimation: tl,
      //         start: "left 5%",
      //         end: "left 60%",
      //         markers: false,
      //         scrub: true,
      //       },
      //     }
      //   );
      // });

      tl.progress(1).progress(0)
      
      
   
      ;
      

     
    
    
  }, []);



  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="horizontalSlider" data-aos='zoom-in' className="sliderSection" ref={wrapperRef}>
      {/* First Slide */}

      <div className="about aboutOne " >
        <Image className="aboutImg" src={slideOne} />
      </div>

      {/* Second Slide */}
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideTwo} />
      </div>

      {/* Third Slide */}

      <div className="about aboutOne">
        <Image className="aboutImg" src={slideThree} />
      </div>

      {/* Fourth Slide */}
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideFour} />
      </div>

      {/* Fifth Slide */}
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideFive} />
      </div>

      {/* Sixth Slide */}

      <div className="about aboutOne">
        <Image className="aboutImg" src={slideSix} />
      </div>
      {/* Seventh Slide */}
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideSeven} />
      </div>

      {/* Eighth Slide */}
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideEight} />
      </div>
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideNine} />
      </div>
      <div className="about aboutOne">
        <Image className="aboutImg" src={slideTen} />
      </div>

      <video autoPlay loop muted playsInline className="sliderBgVideo">
        <source src="/images/vids/backgroundVideoTwo.mp4" type="video/mp4" />
      </video>
    </div>
  );
};
export default SliderSection;
