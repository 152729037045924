import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { GrMoney } from "react-icons/gr";
import { FcAutomatic } from "react-icons/fc";
import { BiLockAlt } from "react-icons/bi";
import { TbIndentIncrease } from "react-icons/tb";
import { AiOutlineSafety } from "react-icons/ai";
import { GiLifeBar } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import { GrConnectivity } from "react-icons/gr";
import {
  FaUserTie,
  FaAngleDoubleUp,
  FaTasks,
  FaUserCheck,
} from "react-icons/fa";
//import "../../assets/scss/elements/_service.scss"

const IotPortfolio = () => {
  return (
    <>
      <section className="services-area iotPorfolio ptb-110">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2 className="theme-gradient text-center capitalText aiHeading">
              IOT APPLICATIONS
            </h2>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/world.png"
                  alt="Smart homes and buildings"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Smart homes and buildings
                </h3>
                <p>
                  loT enable automation of lighting. temperature control,
                  security systems, and energy management.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/logistic.png"
                  alt="Transportation and logistics"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Transportation and logistics{" "}
                </h3>
                <p>
                  loT provide real-time tracking, route optimization, and
                  predictive maintenance for vehlcles and cargo.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/drone.png"
                  alt="Agriculture and farming"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Agriculture and farming
                </h3>
                <p>
                  loT help monitor soil conditions, optimize Irrigation, and
                  Improve crop yield and quality.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/smart-city.png"
                  alt="Smart cities and Infrastructure"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Smart cities and Infrastructure
                </h3>
                <p>
                  loT enable optimization of traffic flow, public transport,
                  waste management, and energy consumption in urban areas.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/asset-management.png"
                  alt=" Asset tracking and management"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Asset tracking and management
                </h3>
                <p>
                  loT provides real-time tracking and management of assets such
                  as vehicles, equipment, and inventory.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/predictive-chart.png"
                  alt="Predictive maintenance and analytics"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Predictive maintenance and analytics
                </h3>
                <p>
                  loT help predict and prevent equipment failures, optimize
                  maintenance schedules, and reduce downtime.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/retail.png"
                  alt="RETAIL AND CONSUMER ELECTRONICS"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">RETAIL AND CONSUMER ELECTRONICS</h3>
                <p>
                  loT enables targeted and personalized marketing, real-lime
                  customer feedback, and improved customer experiences.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/map-location.png"
                  alt="Safety and security systems"
                className="iotIcons"
                />
                </div>

                <h3 className="capitalText aiHeading">
                  Safety and security systems{" "}
                </h3>
                <p>
                  loT provides real-time monitoring of public spaces, emergency
                  response management, and predictive maintenance of critical
                  infrastructure.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                <img
                  src="/images/icons_latest/automation.png"
                  alt="Manufacturing automation"
                className="iotIcons"
                />
                  {/* <i className="flaticon-ceo"></i>
                  <FaTasks fontSize={"65px"} style={{ marginTop: "-15px" }} /> */}
                </div>

                <h3 className="capitalText aiHeading">
                  Manufacturing automation
                </h3>
                <p>
                  loT helps optimize production, monitor, and maintain
                  equipment, and improve supply chain management.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Shape Images */}
        <div className="shape-img2">
          <img src="/images/shape/shape2.svg" alt="shape2.svg" />
        </div>
        <div className="shape-img3">
          <img src="/images/shape/revolvingImageOne.png" alt="shape3" />
        </div>
        <div className="shape-img4">
          <img src="/images/shape/shape4.svg" alt="shape4.svg" />
        </div>
        <div className="shape-img5">
          <img src="/images/shape/shape5.svg" alt="shape5.svg" />
        </div>
        <div className="shape-img3">
          <img src="/images/shape/revolvingImageOne.png" alt="shape3.png" />
        </div>
        <div className="dot-shape1">
          <img src="/images/shape/dot1.png" alt="dot1" />
        </div>
        <div className="dot-shape2">
          <img src="/images/shape/dot3.png" alt="dot3" />
        </div>
        <div className="dot-shape2">
          <img src="/images/shape/dot4.png" alt="dot4" />
        </div>
        <div className="dot-shape2">
          <img src="/images/shape/dot5.png" alt="dot5" />
        </div>
        <div className="dot-shape2">
          <img src="/images/shape/dot6.png" alt="dot6" />
        </div>
      </section>
    </>
  );
};

export default IotPortfolio;
