import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import PredictiveAnalysis from "./pages/PredictiveAnalysis";
import ComputerVision from "./pages/ComputerVision";
import IntelligentAutomation from "./pages/IntelligentAutomation";
import "animate.css";

import Marketing from "./pages/Marketing";
import { useState } from "react";
import Contact from "./elements/contact/Contact";

import "./assets/scss/style.scss";
import GetAQuote from "./elements/get-a-quote/GetAQuote";
import CalltoAction from "./elements/calltoaction/CalltoAction";
import Faqs from "./elements/faqs/Faqs";
import Iot from "./elements/iot/Iot";
import AITechnology from "./elements/ai-technology/AITechnology";
import PrivacyPolicy from "./elements/privacy-policy/PrivacyPolicy";
import { RingLoader } from "react-spinners";

import Aos from "aos";
import "aos/dist/aos.css";
import TermConditions from "./elements/terms-conditions/TermsConditions";
import LanguageProcessing from "./pages/LanguageProcessing";
import CEO from "./elements/about/CEO";
import CTO from "./elements/about/CTO";
import ProfessionalServices from "./pages/ProfessionalServices";
import TurnkeySolutions from "./pages/TurnkeySolutions";
import NetworkingSolutions from "./pages/NetworkingSolutions";
import SecurityServices from "./pages/SecurityServices";
import TelecommunicationServices from "./pages/TelecommunicationServices";
import Integration from "./pages/Integration";
import Consultation from "./pages/Consultation";
import AstroPlanet from "./pages/AstroPlanet";
import Vuzix from "./pages/Vuzix";
import Colop from "./pages/Colop";
import ComingSoon from "./pages/ComingSoon";
import Loader from "./common/loader/Loader";



const App = () => {
  const [loading, setLoading] = useState(true);
const history=useHistory()


   

  window.onbeforeunload = function() {
    const element = document.getElementById("footer");
    console.log('hoyyy')

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      history.push("/");
      const element = document.getElementById("visionSection");

      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  
  useEffect(() => {
    const timerHandle = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timerHandle);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    document.querySelector("body").className = "dark";
  }, []);
  return (
    <>
     <Loader loading={loading}/>
 

        <Router>
          <PageScrollTop>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL + "/"}`}
                exact
                component={Marketing}
              />

              {/* Blog Part  */}

              <Route
                path={`${process.env.PUBLIC_URL + "/contact"}`}
                exact
                component={Contact}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/get-a-quote"}`}
                exact
                component={GetAQuote}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/call-to-action"}`}
                exact
                component={CalltoAction}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/faqs"}`}
                exact
                component={Faqs}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/iot"}`}
                exact
                component={Iot}
              />

              <Route
                // path={`${process.env.PUBLIC_URL + "/ai-technology"}`}
                path={`${process.env.PUBLIC_URL + "/ai-technology"}`}
                exact
                component={AITechnology}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                exact
                component={PrivacyPolicy}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/terms-conditions"}`}
                exact
                component={TermConditions}
              />

              <Route
                path={`${
                  process.env.PUBLIC_URL + "/natural-language-processing"
                }`}
                exact
                component={LanguageProcessing}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/predictive-analysis"}`}
                exact
                component={PredictiveAnalysis}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/computer-vision"}`}
                exact
                component={ComputerVision}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/intelligent-automation"}`}
                exact
                component={IntelligentAutomation}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/ceo"}`}
                exact
                component={CEO}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/cto"}`}
                exact
                component={CTO}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/professional-services"}`}
                exact
                component={ProfessionalServices}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/turn-key-solutions"}`}
                exact
                component={TurnkeySolutions}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/networking-solutions"}`}
                exact
                component={NetworkingSolutions}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/security-services"}`}
                exact
                component={SecurityServices}
              />

              <Route
                path={`${
                  process.env.PUBLIC_URL + "/telecommunication-services"
                }`}
                exact
                component={TelecommunicationServices}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/integration"}`}
                exact
                component={Integration}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/consultation"}`}
                exact
                component={Consultation}
              />

              <Route
                path={`${process.env.PUBLIC_URL + "/astro-planet"}`}
                exact
                component={AstroPlanet}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/vuzix"}`}
                exact
                component={Vuzix}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/colop"}`}
                exact
                component={Colop}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
                exact
                component={ComingSoon}
              />
            </Switch>
          </PageScrollTop>
        </Router>
      
    </>
  );
};

export default App;
