import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as ReactDOMClient from 'react-dom/client';
import '@fortawesome/fontawesome-free/css/all.min.css';


import reportWebVitals from './reportWebVitals';
const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
