import React from "react";
import Layout from "../common/Layout";

import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const AstroPlanet = ({ data }) => {
  return (
    <>
          <SEO title="ASTRO PLANET" />

      <Layout>
        <PageBanner
          pageTitle="ASTRO PLANET"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="ASTRO PLANET"
          bgImgClass="astroPlanet"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className="theme-gradient">ASTRO PLANET MOBILE SLIDER 5GP</h3>
                  <ul data-aos='fade-left'>
                    <h3 className="capitalText">Key features:-</h3>
                    <li>
                      A newly-designed integrated physical backlit keyboard
                    </li>
                    <li>
                      {" "}
                      A large 6.39" AMOLED touchscreen display with Gorilla
                      glass
                    </li>
                    <li> A hi-res 48MP rear camera</li>
                    <li> 8GB of RAM and 128GB of Flash storage</li>
                    <li>
                      {" "}
                      Fingerprint sensor and smart toggle button for security
                      and control
                    </li>
                    <li> NFC for payment</li>
                  </ul>

                  <ul data-aos='fade-left'>
                    <h3 className="capitalText">Quick Facts:-</h3>
                    <li>
                      {" "}
                      First 5G handheld device with full physical keyboard
                    </li>
                    <li>
                      {" "}
                      RockUp™ slider hinge mechanism combines all the power of a
                      mini laptop with the functionality of a smartphone
                    </li>
                    <li>
                      {" "}
                      Device features a large AMOLED 6.39" touchscreen display
                      and a 48MP camera
                    </li>
                    <li>
                      {" "}
                      Astro runs Android 11 and also supports Linux OSs, with a
                      multi-boot option
                    </li>
                    <li>
                      {" "}
                      Big enough to be the only device you need to carry, and
                      small enough to fit in your pocket, it’s a true all-in-one
                      replacement for both your mobile phone and your laptop
                    </li>
                  </ul>
                  <table>
                    <thead>
                      <tr data-aos='fade-right'>
                        <td>&nbsp;</td>
                        <th>&nbsp;</th>
                        <th>Astro Slide (Initial Specification)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr data-aos='fade-right'>
                        <th>
                          <strong>
                            <span>Network: </span>
                          </strong>
                        </th>
                        <td>
                          <p>Wi-Fi</p>
                        </td>
                        <td>
                          <p>WiFi 5 2T2R</p>
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>
                          <p>Modem</p>
                        </td>
                        <td>
                          <p>Dual 5G+4G World-wide modem</p>
                          <p>
                            3GPP Rel. 15
                            <br />
                            NSA/SA
                            <br />
                            DL 2.39Gbps
                            <br />
                            2CC UL 2.39Gbps
                            <br />
                            2CC Sub-6GHz
                            <br />
                            2G / 3G / 4G / 5G Multi-Mode
                          </p>
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>VoLTE</td>
                        <td>Compatible</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>ViLTE</td>
                        <td>Compatible</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>VoWi-Fi</td>
                        <td>Compatible</td>
                        <th>&nbsp;</th>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Dual SIM operation</td>
                        <td>Dual Model 5G + 4G</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Body:</strong>{" "}
                          </span>
                        </th>
                        <td>Form Factor</td>
                        <td>RockUp™ Slider / Transformer</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Dimensions</td>
                        <td>
                          17.24 (W) x 7.65 (D) x 1.78 (H) cm , 1.87 with camera
                          protrusion (H)
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Weight</td>
                        <td>Approx. 300g</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>SIM</td>
                        <td>Dual nanoSIM + eSIM chip</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Display:</strong>{" "}
                          </span>
                        </th>
                        <td>Type</td>
                        <td>AMOLED Colour FHD+ (20 : 9)</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Size</td>
                        <td>6.39 inch</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Resolution</td>
                        <td>2340x1080, 403 ppi</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Multi Touch</td>
                        <td>Yes</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Protection</td>
                        <td>
                          Scratch resistant Gorilla glass (Corning 3rd gen)
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Platform:</strong>{" "}
                          </span>
                        </th>
                        <td>OS</td>
                        <td>Android 11 OS , Linux OS compatible</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Chipset</td>
                        <td>
                          MediaTek Dimensity 800, MT6873 Octa-Core 5G SoC&nbsp;
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>CPU</td>
                        <td>
                          4x A77 @ 2.6GHz
                          <br />
                          4x A55 @ 2.0GHz&nbsp;
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>GPU</td>
                        <td>Arm Mali-G57 MC4 GPU Manhattan 3.0: 52fps</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>APU</td>
                        <td>3rd Generation Quad-core APU 3.0. Up to 2.5TOPS</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Memory:</strong>{" "}
                          </span>
                        </th>
                        <td>
                          <p>RAM</p>
                          <p>Card Slot</p>
                        </td>
                        <td>
                          <p>8GB -&nbsp; Ultra-fast 4-channel LPDD4x</p>
                          <p>microSD</p>
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Internal</td>
                        <td>128 GB</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Camera:</strong>{" "}
                          </span>
                        </th>
                        <td>Rear Camera</td>
                        <td>48MP + Flash (Sony Sensor)</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Front Camera</td>
                        <td>13MP</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Sound:</strong>{" "}
                          </span>
                        </th>
                        <td>Speakers</td>
                        <td>Stereo Speakers</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Microphone</td>
                        <td>Integrated ambient Microphone</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>3.5mm Jack</td>
                        <td>Yes</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Comms:</strong>{" "}
                          </span>
                        </th>
                        <td>WLAN</td>
                        <td>802.11 a/b/g/n/ac/e/k/r/h&nbsp;</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>Bluetooth</td>
                        <td>v5.1</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>GPS</td>
                        <td>
                          AGPS, Glonass, Beidou, Galileo<p>Band L1 + L5 GNSS</p>
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>NFC</td>
                        <td>Yes, including payment</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>FM Radio</td>
                        <td>Yes</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>USB</td>
                        <td>
                          2x USB Type C, OTG support, Display Port support (via
                          adapter cable)
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Features:</strong>{" "}
                          </span>
                        </th>
                        <td>Keyboard</td>
                        <td>
                          <p>Mechanical full keyboard</p>
                          <p>24 Layouts</p>
                          <p>
                            Arabic, Chinese, Croatian, Czech, Dvorak, Finnish /
                            Swedish, French, German, Greek, Hebrew, Hungarian,
                            Icelandic, Italian, Japanese, Korean, Norwegian /
                            Danish, Polish, Portuguese, Russian, Spanish, Swiss,
                            Thai, English UK, English US
                          </p>
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>
                          <p>Charging</p>
                        </td>
                        <td>10W Qi&nbsp;Wireless charging + Fast charging</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>
                          <p>Sensors</p>
                        </td>
                        <td>
                          Accelerometer, compass, light sensor, gyro,
                          magneto-sensor
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>&nbsp;</th>
                        <td>
                          Fingerprint
                          <br />
                          <br />
                          Buttons
                        </td>
                        <td>
                          Finger print sensor on side on/off button
                          <br />
                          <br />
                          Volume up / down, on/off/lock button with fingerprint
                          sesnor , smart control button
                        </td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Battery:</strong>{" "}
                          </span>
                        </th>
                        <td>&nbsp;</td>
                        <td>LiPo 4000mAh battery&nbsp;</td>
                      </tr>
                      <tr data-aos='fade-right'>
                        <th>
                          <span>
                            <strong>Misc:</strong>{" "}
                          </span>
                        </th>
                        <td>Colour</td>
                        <td>Black&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default AstroPlanet;
