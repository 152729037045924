import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabTwo = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 ">
              <div className="col-lg-5">
                <img
                  className="radius-small"
                  src="./images/iotpicture.jpg"
                  alt="Corporate React Template"
                />
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab style-two">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>PERCEPTION</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>NETWORK</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>APPLICATION</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            The perception layer of an IoT device architecture
                            consists of sensors and actuators that can collect,
                            accept, and process data. The Physical Layer is
                            responsible for connecting objects in an environment.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                          The network layer is responsible for connecting to other smart things, network devices, and servers.  It provides communication and connectivity between devices in the system.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                          Is a cloud technology which is the layer that allows IoT devices to communicate with each other. The Application Layer handles tasks such as authentication, authorization, and analytics.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabTwo;
