import React from 'react';

import AISolutions from '../ai-solutions/AISolutions';



const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/earth.jpg)`}} data-black-overlay="8">
           <AISolutions/>
        </div>
    )
}
export default CalltoActionTwo;