import Layout from "../common/Layout";

import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const TurnkeySolutions = () => {
  return (
    <>
    <SEO title={'TURNKEY SOLUTIONS'}/>
      <Layout>
        <PageBanner
          pageTitle="TURNKEY SOLUTIONS"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="TURNKEY SOLUTIONS"
          bgImgClass="turnkey"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className="theme-gradient" data-aos="fade-right">
                    TURNKEY SOLUTIONS
                  </h3>
                  <p data-aos="fade-right">
                    At METANOW, we offer a complete range of turnkey solutions
                    to help businesses of all sizes embrace digital
                    transformation. Our turnkey solutions include everything you
                    need to get started with loT, from hardware and software to
                    integration, consultation, and professional services.
                  </p>

                  <p data-aos="fade-right">
                    Our turnkey solutions are designed to meet the unique needs
                    of your business, and can be tailored to your specific
                    requirements. We work closely with you to understand your
                    goals and challenges, and then design a solution that
                    addresses your specific needs.
                  </p>
                  <h4 className="capitalText" data-aos="fade-right">
                    Our turnkey solutions includes
                  </h4>
                  <ul data-aos="fade-left">
                    <li>
                      loT devices and sensors to capture data from your physical
                      assets
                    </li>
                    <li>
                      Software to manage, analyze, and visualize your data
                    </li>
                    <li>
                      Integration services to ensure that your loT solutions
                      integrate smoothly with your existing systems
                    </li>
                    <li>
                      Consultation services to help you develop a clear strategy
                      for your digital transformation journey{" "}
                    </li>
                    <li>
                      Software to manage, analyze, and visualize your data
                    </li>
                    <li>
                      Integration services to ensure that your loT solutions
                      integrate smoothly with your existing systems
                    </li>
                    <li>
                      Consultation services to help you develop a clear strategy
                      for your digital transformation journey
                    </li>
                    <li>
                      Professional services to ensure a successful rollout and
                      ongoing support of your loT solutions
                    </li>
                  </ul>

                  <p data-aos="fade-left">
                    By utilizing our turnkey solutions, you can have complete
                    confidence that you're receiving top-of-the-line technology
                    and specialized knowledge that will assist you in maximizing
                    your investment in IoT and making the most of your digital
                    transactions.
                  </p>
                  <p data-aos="fade-left">
                    If you're looking for a simple, cost effective way to
                    embrace digital transformation, our turnkey solutions are
                    the perfect solution. Contact us today to learn more about
                    how we can help you take your business to the next level.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TurnkeySolutions;
