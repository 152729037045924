import PageBanner from "../common/page-banner/PageBanner";
import Layout from "../common/Layout";
import SEO from "../common/SEO";

const ProfessionalServices = () => {
  return (
    <>
    <SEO title={'PROFESSIONAL SERVICES'}/>
      <Layout>
        <PageBanner
          pageTitle="PROFESSIONAL SERVICES"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="PROFESSIONAL SERVICES"
          bgImgClass="proService"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className="theme-gradient" data-aos="fade-right">
                    PROFESSIONAL SERVICES
                  </h3>
                  <p data-aos="fade-right">
                    At METANOW, we understand that successful digital
                    transformation requires more than just technology. That's
                    why we offer a full range of professional services to
                    support you every step of the way.
                  </p>
                  <h4 className="capitalText" data-aos="fade-right">
                    Our professional services include
                  </h4>
                  <ul data-aos="fade-left">
                    <li>
                      {" "}
                      Project management and implementation services to ensure a
                      successful rollout of your loT solutions
                    </li>
                    <li>
                      Training and support services to ensure that your staff is
                      fully equipped to use and maintain your loT, solutions
                    </li>
                    <li>
                      Maintenance and support services to keep your loT
                      solutions running smoothly and address any technical
                      issues that may arise
                    </li>
                    <li>
                      Upgrade and expansion services to help you stay ahead of
                      the curve as your business grows and evolves
                    </li>
                  </ul>
                  <p data-aos="fade-left">
                    Our team of experts is dedicated to delivering high quality
                    professional services that meet your unique needs and goals.
                    We work closely with you to understand your requirements,
                    and then develop and implement a customized solution that
                    addresses your specific challenges and opportunities.
                  </p>
                  <p data-aos="fade-left">
                    We are committed to providing the highest level of customer
                    service and support. Our team is always available to answer
                    any questions and provide ongoing maintenance to ensure
                    smooth operation of your loT system.
                  </p>

                  <p data-aos="fade-left">
                    Let us help you achieve your digital transformation goals
                    with our full range of professional services. Contact us
                    today to learn more about how we can help you take your
                    business to the next level.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProfessionalServices;
