import Layout from "../common/Layout";
import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const SecurityServices = () => {
  return (
    <>
    <SEO title='CYBER SECURITY'/>
    <Layout>
      <PageBanner
        pageTitle="CYBER SECURITY"
        homePageUrl="/"
        homePageText="HOME"
        activePageText="CYBER SECURITY"
        bgImgClass="security"
      />

      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <h3 className="theme-gradient">CYBER SECURITY</h3>
                <p data-aos="fade-right">
                  Protect your business and its data with our comprehensive
                  security. At METANOW, we understand the importance of a secure
                  and resilient infrastructure, and we're committed to
                  delivering the best possible solution for your business.
                </p>

                <h4 data-aos="fade-right" className="capitalText">
                  Our security include
                </h4>

                <ul data-aos="fade-left">
                  <li>Network security and firewalls</li>
                  <li>Data encryption and backup solutions</li>
                  <li>Identity and access management</li>
                  <li>Vulnerability assessments and penetration testing</li>
                  <li>Disaster recovery and business continuity planning</li>
                </ul>

                <p data-aos="fade-left">
                  With our security, you can rest assured that your business is
                  protected against cyber threats, data breaches, and other
                  potential security incidents. Our team of experts will work
                  with you to understand your specific needs and design a
                  solution that fits your budget and requirements.
                </p>
                <p data-aos="fade-left">
                  We use the latest technology and best practices to ensure that
                  your infrastructure is secure and compliant with industry
                  standards.
                </p>
                <p data-aos="fade-left">
                  Whether you need to integrate with existing systems or build a
                  brand new solution, we're here to help.
                </p>
                <p data-aos="fade-left">
                  To learn more about our security and how we can help protect
                  your business, contact us today. Our team of experts will be
                  happy to answer any questions you may have and provide a
                  personalized consultation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default SecurityServices;
