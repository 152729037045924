import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

import PageBanner from "../../common/page-banner/PageBanner";
import ContactFormContent from "./ContactFormContent";

const Contact = () => {
  return (
    <>
      <SEO title="CONTACT " />
      <Layout>
        <PageBanner
          pageTitle="CONTACT US"
          homePageUrl="/"
          homePageText="Home"
          activePageText="CONTACT US"
          bgImgClass="contact"
        />
        <ContactFormContent />
      </Layout>
    </>
  );
};
export default Contact;
