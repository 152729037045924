import React, { Component } from "react";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";

const ContactFormContent = () => {
  return (
    <section className="contact-area ptb-110">
      <div className="container">
        <div className="section-title">
          {/* <span className='contactSpan'>Message Us</span> */}
          <h2 className="theme-gradient text-center capitalText">
          DROP A MESSAGE FOR ANY INQUIRY
          </h2>
         
        </div>

        <div className="contact-form">
          <div className="row ">
            <div className="col-lg-5 col-md-12">
              <div className="contact-image">
                <img src="/images/contactNew.jpg" alt="contact" />
              </div>
            </div>

            <div className="col-lg-7 col-md-12 form-container">
              <ContactForm />
            </div>
          </div>
        </div>

        {/* Contact info */}
        <div className="contact-info">
          <div className="contact-info-content">
            <ul className="social">
              <li>
                <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFormContent;
