import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";

import FooterTwo from "../common/footer/FooterTwo";
import ServiceOne from "../elements/service/ServiceOne";
import SliderSection from "../elements/service/SliderSection";

import SectionTitle from "../elements/sectionTitle/SectionTitle";

import Separator from "../elements/separator/Separator";

import TestimonialOne from "../elements/testimonial/TestimonialOne";

import Video from "../elements/video/Video";
import CalltoAction from "../elements/calltoaction/CalltoAction";

const Marketing = () => {
  return (
    <>
      <SEO title="METANOW" />
      <main className="page-wrapper ">
        {/* <HeaderTopBar /> */}
        <div className="headerDiv">
          {" "}
          <HeaderOne
            btnStyle="btn-small round btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        {/* Start Slider Area  */}
        <div className="contentExceptHeader">
          <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
            <div className="container ">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                  <div className="inner text-start">
                    <h1 className="title theme-gradient display-two capitalText ">
                      TECHNOLOGY AND INNOVATION WHAT MATTERS{" "}
                    </h1>
                    <p className="banner-description">
                      Welcome to our digital transformation business,
                      specializing in Internet of Things (IoT) solutions!
                    </p>
                    <p className="banner-description">
                      Unleash the full potential of technology with customized
                      solutions designed to fit your needs. From hardware
                      integration to software development, we bring innovation
                      to your fingertips, streamlining processes and increasing
                      efficiency. At METANOW we value your success, together we
                      shape the future.
                      <p className="theme-gradient slogan pt-2">TOGETHER WE CAN!</p>
                    </p>

                    <form className="banner-form">
                      <input
                        type="email"
                        className="input-newsletter"
                        placeholder="Enter your email"
                        name="EMAIL"
                        required
                      />
                      <button type="submit" className="subscribeBtn">
                        SUBSCRIBE FOR LATEST NEWS
                      </button>
                    </form>
                  </div>
                </div>

                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="ml-main-banner-image">
                    <img src="/images/home-7/banner/hero_section_image_3.webp" alt="imageAlt" />

                    <div className="wrap-shape">
                      <img
                        src="/images/home-7/banner/shape3.png"
                        alt="imageAlt"
                      />
                    </div>
                  </div>
                </div>

                <div className="ai-main-banner-shape3">
                  <img src="/images/home-7/banner/shape4.png" alt="imageAlt" />
                </div>
              </div>
            </div>
          </div>
          {/* End Slider Area  */}

          <Separator />

          {/* Start Service Area  */}
          <div className="rn-service-area  ">
            <div className="">
              <div className="row"></div>
              <ServiceOne
                serviceStyle="service__style--1 icon-circle-style"
                textAlign="text-center"
              />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />

          {/* Start About us */}

          <div className="sliderTopDiv">
            <SliderSection />
          </div>

          <Separator />

          {/* End About us */}

          <CalltoAction />

          <Video />

          {/* End Video Area */}
          {/* Start Testimonial Area  */}
          <div className="rwt-testimonial-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Client Feedback"
                    title="What our clients are Saying"
                    description="Our satisfied clients, can see how our products and services have made a difference."
                  />
                </div>
              </div>
              <TestimonialOne
                column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                teamStyle="card-style-default testimonial-style-one "
              />
            </div>
          </div>
          {/* End Testimonial Area  */}

          <Separator />

          <FooterTwo />
        </div>
        {/* <Copyright /> */}
      </main>
    </>
  );
};

export default Marketing;
