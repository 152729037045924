import Layout from "../../common/Layout"
import PageBanner from "../../common/page-banner/PageBanner"
import SEO from "../../common/SEO"
import Separator from "../separator/Separator"


const PrivacyPolicy = () => {


  return (


    <>
    <SEO title={'PRIVACY POLICY'}/>
      <Layout>
        <PageBanner
          pageTitle="PRIVACY POLICY"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="PRIVACY POLICY (Last updated: 19 – 02 - 2023)"
        />

        <div className="text-container pt-100"><div className="container"><p>We at METANOW are committed to protecting the privacy of our customers and visitors to our website. This Privacy Policy outlines how we collect, use, and protect the personal information we gather.</p></div></div>
        <div className="text-container pt-60 pb-100 ">
          <div className="container">
            <h4>COLLECTION OF PERSONAL INFORMATION</h4>

            <p>
            We collect personal information when visitors to our website subscribe to our newsletter or fill out a contact form. The personal information we collect includes names, email addresses, phone numbers, and any other information visitors choose to provide.
            </p>

            <h4>USE OF PERSONAL INFORMATION</h4>
            <p>
            We use the personal information we collect to provide our customers with the best possible service. This includes processing quotes, providing support, and keeping customers informed of the latest news and updates.
            </p>

          

            <h4>STORAGE AND PROTECTION OF PERSONAL INFORMATION</h4>
            <p>
            We store and protect personal information in the backend by developing a database that is protected by industry-standard security measures.
            </p>

            <h4>ACCESS TO PERSONAL INFORMATION</h4>

            <p>
            Employees and third-party service providers may access personal information in order to provide support, process quotes, or improve our website. We require that these parties maintain the confidentiality and security of the personal information they access.
            </p>
            <h4>SHARING OF PERSONAL INFORMATION</h4>
            <p>We may share personal information with third-party service providers who provide analytics and hosting services for our website. We may also share personal information in order to comply with legal requirements or to protect our legal rights.</p>

            <h4>ACCESS TO AND CORRECTION OF PERSONAL INFORMATION</h4>
            <p>Customers and visitors to our website can access, correct, or delete their personal information by filling out a form on our website.</p>
            <h4>COMPLIANCE WITH LAWS AND REGULATIONS</h4>
            <p>We are committed to complying with all applicable laws and regulations regarding the collection, use, and protection of personal information.</p>


            <p>If you have any questions or concerns about our privacy practices, please contact us by filling out a contact form on our website.</p>
          </div>
        </div>
        <Separator/>
      </Layout>
    </>
  )

}

export default PrivacyPolicy