import Layout from "../common/Layout";
import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const Integration = () => {
  return (
    <>
    <SEO title={'INTEGRATION'}/>
      <Layout>
        <PageBanner
          pageTitle="INTEGRATION"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="INTEGRATION"
          bgImgClass="integration"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className="theme-gradient" data-aos="fade-right">
                    INTEGRATION
                  </h3>
                  <p data-aos="fade-right">
                    At Metanow, we understand that your business is not a
                    collection of isolated systems, but rather a complex
                    ecosystem that requires seamless integration of various
                    technologies and processes. Our team of integration experts
                    can help you connect different applications and systems to
                    optimize your operations and enhance your customer
                    experience.
                  </p>

                  <p data-aos="fade-right">
                    We offer integration services that range from simple
                    integrations to complex enterprise-level integrations.
                  </p>

                  <h4 className="capitalText" data-aos="fade-left">Our services includes</h4>
                  <ul data-aos="fade-right">
                    <li>
                      <b>Application integration:</b> We can help you connect
                      different applications to create a seamless experience for
                      your customers and employees. Whether it's connecting your
                      CRM system to your marketing automation platform or
                      integrating your accounting software with your e-commerce
                      platform, we can help you streamline your operations and
                      improve your productivity.
                    </li>
                    <li>
                      <b>Data integration:</b> We can help you integrate your
                      data sources to create a unified view of your data. By
                      connecting your data sources, you can gain insights into
                      your business that would otherwise be impossible. We can
                      help you with data warehousing, ETL processes, and data
                      migration.
                    </li>
                    <li>
                      <b>API integration:</b> We can help you create APIs that
                      connect your systems and applications. APIs can help you
                      create a more flexible and agile infrastructure that can
                      adapt to changing business needs. We can help you with API
                      design, development, and management.
                    </li>
                    <li>
                      <b>Cloud integration:</b> We can help you integrate your
                      cloud services and applications. Whether you're using
                      Microsoft Azure, Amazon Web Services, or Google Cloud, we
                      can help you integrate your cloud services with your
                      on-premise systems.
                    </li>
                  </ul>

                  <p data-aos="fade-left">
                    Our integration experts have extensive experience in
                    integrating various systems and applications. We use a
                    proven methodology that ensures a smooth integration process
                    and minimizes disruption to your business operations.
                  </p>
                  <p data-aos="fade-left">
                    Contact us today to learn more about our integration
                    services and see how we can help you achieve your business
                    goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Integration;
