import React, { Component } from 'react';
import './loader.css'
const Loader=(props)=> {
    const text = "Loading...";
    const rings = 2;
    const ringSectors = 30;
    
        return (
            <div className={`preloader ${props.loading ? '' : 'preloader-deactivate'}`}>
                <div className="loader">
                    <div ></div>
                    <div ></div>

  
<div class="preloaderNew">
  <div class="preloader__ring">
    <div class="preloader__sector">M</div>
    <div class="preloader__sector">E</div>
    <div class="preloader__sector">T</div>
    <div class="preloader__sector">A</div>
    <div class="preloader__sector">N</div>
    <div class="preloader__sector">O</div>
    <div class="preloader__sector">W</div>
    <div class="preloader__sector">.</div>
    <div class="preloader__sector">.</div>
    <div class="preloader__sector">.</div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
  </div>
  <div class="preloader__ring">
    <div class="preloader__sector">M</div>
    <div class="preloader__sector">E</div>
    <div class="preloader__sector">T</div>
    <div class="preloader__sector">A</div>
    <div class="preloader__sector">N</div>
    <div class="preloader__sector">O</div>
    <div class="preloader__sector">W</div>
    <div class="preloader__sector">.</div>
    <div class="preloader__sector">.</div>
    <div class="preloader__sector">.</div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
    <div class="preloader__sector"></div>
  </div>
</div>

    
                </div>
            </div>
        );
    
}

export default Loader;