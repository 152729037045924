import Layout from "../../common/Layout";
import PageBanner from "../../common/page-banner/PageBanner";
import SEO from "../../common/SEO";
import ceoImg from "./ceo.webp";

const CEO = () => {
  return (
    <>
    <SEO title='CHIEF EXECUTIVE OFFICER'/>
      <Layout>
        <PageBanner
          pageTitle="CHIEF EXECUTIVE OFFICER"
          homePageUrl="/"
          homePageText="Home"
          activePageText="CHIEF EXECUTIVE OFFICER"
          bgImgClass="item-bg3"
        />

        <div className="services-details-area ptb-110">
          <div className="container" >
            <div className="services-details-overview"  data-aos="fade-right">
              <div className="services-details-image">
                <img
                  src={ceoImg}
                  className="aboutImgTwo"
                  style={{ objectFit: "cover" }}
                  alt="ceoImg"
                  
                />
              </div>

              <div className="services-details-desc" data-aos="fade-left">
                <h2
                  className="capitalText theme-gradient ceoName"
                  
                >
                  Amer Achour
                </h2>
                <h4
                  className="about-subtitle capitalText"
               
                >
                  Chief Executive Officer
                </h4>

                <p >
                  Welcome to METANOW, a company built on a foundation of
                  innovation, expertise, and excellence. As the CEO of this
                  dynamic organization, I am privileged to lead a team of the
                  most talented professionals in the industry. Our focus is on
                  delivering unparalleled value to our clients through
                  cutting-edge technology and superior customer service.
                </p>
                <p >
                  At METANOW, we are committed to building lasting relationships
                  with our clients by understanding their unique needs and
                  delivering tailored solutions that exceed expectations. This
                  commitment reflects our core values, which are centered on
                  integrity, respect, and collaboration.
                </p>
                <p >
                  As a leader, I bring to the table a unique set of skills and
                  experience that have enabled me to achieve success in the UAE
                  market. With a strategic mindset, a problem-solving approach,
                  and a deep understanding of the industry, I have been able to
                  deliver results that have earned me a reputation as one of the
                  most innovative specialists in the field. My dedication to
                  excellence is matched only by my commitment to the success of
                  our clients.
                </p>
                <p >
                  I am proud to lead a team that is highly respected by
                  colleagues and clients alike, and I am confident that our
                  expertise, combined with our focus on innovation, will
                  continue to drive us to new heights of success. Together, we
                  will achieve greatness and lead METANOW to glory. Thank you
                  for choosing to partner with us on this exciting journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CEO;
