import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
const FooterTwo = () => {
  let currentYear = new Date().getFullYear();
  const history = useHistory();

  const navToVisionSection = () => {
    const element = document.getElementById("visionSection");

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      history.push("/");
      const element = document.getElementById("visionSection");

      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="footer-wrap-area pt-100" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget slogan-widget">
              <div className="logo">
                <Link to={'/'}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logoFooterFinal.png`}
                    alt="footer-logo"
                    width={"250px"}
                  />
                </Link>
                <h3 className="footerText under-line">METANOW</h3> 
              </div>
              <div className="sloganContainerFooter">
                <h3 className=" footer-slogan together">TOGETHER </h3> 
                <h3 className=" footer-slogan weCan"> WE CAN</h3> 
                </div>

              <ul className="social">
                <li>
                  <Link>
                    <i className="flaticon-facebook-letter-logo"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="flaticon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="flaticon-instagram-logo"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="flaticon-youtube-play-button"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget ">
              <h3 className="company-widget">COMPANY</h3>

              <ul className="footer-services-list">
                <li>
                  <Link href="" onClick={navToVisionSection}>
                    OUR VISION AND MISSION
                  </Link>
                </li>
                {/* <li>
                  <Link to="/ceo">MEET TEAMETA</Link>
                </li> */}
                <li>
                  <Link to="/ceo">CHIEF EXECUTIVE OFFICER</Link>
                </li>
                <li>
                  <Link to="/cto">CHIEF TECHNICAL OFFICER</Link>
                </li>
                <li>
                  <Link to={'/coming-soon'}>BLOGS & EVENTS</Link>
                </li>
                <li>
                  <Link to="/get-a-quote">GET A QUOTE</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3 className="company-widget">PRODUCTS & SERVICES</h3>

              <ul className="quick-links-list">
                <li>
                  <Link to={"/vuzix"}>EXCLUSIVE PRODUCTS</Link>
                </li>
                <li>
                  <Link to={"/iot"}>INTERNET OF THINGS</Link>
                </li>
                <li>
                  <Link to={"/telecommunication-services"}>
                    INFORMATION COMMUNICATION TECHNOLOGY
                  </Link>
                </li>
                <li>
                  <Link to={"/turn-key-solutions"}>TURN-KEY SOLUTIONS</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget useful-footer-links ">
              <h3 className="capitalText company-widget">Useful Links </h3>

              <ul className="footer-contact-list">
                <li>
                  <a
                    href="https://tdra.gov.ae/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    TDRA
                  </a>
                </li>
                <li>
                  <a
                    href="https://icp.gov.ae/en/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    UAEICP
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dubaipolice.gov.ae/wps/portal/home"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    DUBAI POLICE
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.digitaldubai.ae/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    DIGITAL DUBAI
                  </a>
                </li>
                <li className="capitalText">
                  <a
                    href="https://www.sira.gov.ae/en/home.aspx"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Security Industry and regulatory Agency{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <p className="copyright">
                Copyright &copy;{currentYear}. All Rights Reserved Metanow.
              </p>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-sm-12">
              <ul className="social">
                <li>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-map">
        <img src="/images/circle-map.png" alt="image" />
      </div>

      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className="footer-wrap-shape-1">
        <img src="/images/shape-1.png" alt="image" />
      </div>
      <div className="footer-wrap-shape-2">
        <img
          src="/images/revolvingImageFour.png"
          alt="image"
          className="revolvingImageFour"
        />
      </div>
      <div className="footer-wrap-shape-3">
        <img src="/images/footerShape.png" alt="image" />
      </div>
    </footer>
  );
};

export default FooterTwo;
