import { Link } from "react-router-dom";
import { FaReact } from "react-icons/fa";
const AIContent = () => {
  return (
    <>
      <div className="services-details-area ptb-110">
        <div className="container">
          <div className="services-details-overview">
            <div className="services-details-desc mb-30" data-aos="fade-right">
              <h3 className="theme-gradient">AI TECHNOLOGY SERVICES</h3>
              <p>
                {" "}
                Artificial Intelligence (AI) technology services refer to the
                various services that utilize AI technology to provide solutions
                to complex problems. AI technology is the simulation of human
                intelligence processes by machines, especially computer systems,
                to solve problems that usually require human intelligence to
                solve.
              </p>
            </div>
            <div className="services-details-image" data-aos="fade-left">
              <img src="/images/aiImage.jpg" alt="imageAi" />
            </div>
          </div>
        </div>
      </div>
      <div className="solutions-area ptb-110">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <h3 className="theme-gradient text-center capitalText">
              Artificial intelligence advantages
            </h3>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-income"></i>
                 <img src="/images/ai-icons/benchmarking.png"/>
                </div>
                <h3 className="capitalText aiHeading">Efficiency</h3>
                <p>
                  Al can automate routine and repetitive tasks, enabling humans
                  to focus on more complex and creative tasks. This improves
                  efficiency and productivityin various fields.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box ">
                <div className="icon">
                  <i className="flaticon-automatic"></i>
                  <img src="/images/ai-icons/accurate.png"/>
                </div>
                <h3 className="capitalText aiHeading">Accuracy</h3>
                <p>
                  Al can perform tasks with a high level of accuracy, reducing
                  human errors and improving quality in fields such as
                  healthcare and manufacturing.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-locked"></i>
                  <img src="/images/ai-icons/choice.png"/>
                </div>
                <h3 className="capitalText aiHeading">Decision-making</h3>
                <p>
                  Al can analyze vast amounts of data to help humans make
                  data-driven decisions, improving decision-making and reducing
                  the risk of errors.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-molecular"></i>
                  <img src="/images/ai-icons/database.png"/>
                </div>
                <h3 className="capitalText aiHeading">Scalability</h3>
                <p>
                  Al can scale easily to handle large amounts of data and
                  perform complex tasks, making it ideal for fields such as
                  finance and e-commerce.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-gear"></i>
                  <img src="/images/ai-icons/skills.png"/>
                </div>
                <h3 className="capitalText aiHeading">Continuous learning</h3>
                <p>
                  Al can learn and improve from experience, enabling it to adapt
                  to changing situations and improve its performance over time.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-ceo"></i>
                  <img src="/images/ai-icons/24-hour-service.png"/>
                </div>
                <h3 className="capitalText aiHeading">24/7 Availability</h3>
                <p>
                  Al can work around the clock without fatigue, providing
                  uninterrupted services and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIContent;
