import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import TopSolutions from "../top-solutions/TopSolutions";

const CalltoActionOne = () => {
  return (
    <div
      className="rn-callto-action clltoaction-style-default bg_image bg_image_fixed"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/earth.jpg)`,
      }}
      data-black-overlay="7"
    >
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <TopSolutions />
      </ScrollAnimation>
    </div>
  );
};
export default CalltoActionOne;
