import React, { useEffect } from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import '../../../src/assets/scss/template/_banner.scss'

const Nav = () => {
  const history = useHistory();
  const [AboutItemMainHover, setAboutItemMainHover] = useState(false);

  const [AboutItemOneHover, setAboutItemOneHover] = useState(false);
  const [AboutItemTwoHover, setAboutItemTwoHover] = useState(false);
  const [AboutItemThreeHover, setAboutItemThreeHover] = useState(false);

  const [ProductItemMainHover, setProductItemMainHover] = useState(false);

  const [ProductItemOneHover, setProductItemOneHover] = useState(false);
  const [ProductItemTwoHover, setProductItemTwoHover] = useState(false);
  const [ProductItemThreeHover, setProductItemThreeHover] = useState(false);

  const [ServiceItemMainHover, setServiceItemMainHover] = useState(false);
  const [ServiceItemOneHover, setServiceItemOneHover] = useState(false);
  const [ServiceItemTwoHover, setServiceItemTwoHover] = useState(false);
  const [ServiceItemThreeHover, setServiceItemThreeHover] = useState(false);
  const [ServiceItemFourHover, setServiceItemFourHover] = useState(false);
  const [ServiceItemFiveHover, setServiceItemFiveHover] = useState(false);

  const [ContactItemMainHover, setContactItemMainHover] = useState(false);

  const [ContactItemOneHover, setContactItemOneHover] = useState(false);
  const [ContactItemTwoHover, setContactItemTwoHover] = useState(false);
  const [ContactItemThreeHover, setContactItemThreeHover] = useState(false);
  const [ContactItemFourHover, setContactItemFourHover] = useState(false);

  const [subItemOneHover, setSubItemOneHover] = useState(false);
  const [subItemTwoHover, setSubItemTwoHover] = useState(false);

  const [ictSubItemOneHover, setICTSubItemOneHover] = useState(false);
  const [ictSubItemTwoHover, setICTSubItemTwoHover] = useState(false);
  const [ictSubItemThreeHover, setICTSubItemThreeHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [colopHover, setColopHover] = useState(false);
  const [planetHover, setPlanetHover] = useState(false);
  const [turnkeyHover, setTurnkeyHover] = useState(false);
  const [telecomHover, setTelecomHover] = useState(false);

  const [consultationHover, setConsultationHover] = useState(false);

  const [hoverHeading, setHoverHeading] = useState(null);
  let contentExceptHeader;
  contentExceptHeader = document.querySelector(".contentExceptHeader");
  const reduceOpacity = () => {
    contentExceptHeader.style.opacity = "0.3";
  };

  const defaultOpacity = () => {
    contentExceptHeader.style.opacity = "1";
  };

  const handleMouseEnterAbout = (e) => {
    setAboutItemMainHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseLeaveAbout = () => {
    setAboutItemMainHover(false);
    setHoverHeading(null);
  };

  const handleMouseEnterService = (e) => {
    setServiceItemMainHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseLeaveService = (e) => {
    setServiceItemMainHover(false);
    setHoverHeading(null);
  };
  const handleMouseEnterProduct = (e) => {
    setProductItemMainHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseLeaveProduct = () => {
    setProductItemMainHover(false);
    setHoverHeading(null);
  };
  const handleMouseEnterSubOne = (e) => {
    setSubItemOneHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseLeaveSubOne = (e) => {
    setSubItemOneHover(false);
    setHoverHeading(null);
  };

  const handleMouseEnterSubTwo = (e) => {
    setSubItemTwoHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseLeaveSubTwo = (e) => {
    setSubItemTwoHover(false);
    setHoverHeading(null);
  };

  const handleMouseEnterICTSubOne = (e) => {
    setICTSubItemOneHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseEnterICTSubTwo = (e) => {
    setICTSubItemTwoHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseEnterICTSubThree = (e) => {
    setICTSubItemThreeHover(true);
    setHoverHeading(e?.target?.textContent);
  };

  const handleMouseLeaveICTSubOne = (e) => {
    setICTSubItemOneHover(false);
    setHoverHeading(null);
  };

  const handleMouseLeaveICTSubTwo = (e) => {
    setICTSubItemTwoHover(false);
    setHoverHeading(null);
  };

  const handleMouseLeaveICTSubThree = (e) => {
    setICTSubItemThreeHover(false);
    setHoverHeading(null);
  };

  const handleMouseEnterOne = (e) => {
    setAboutItemOneHover(true);
    setAboutItemTwoHover(false);

    setHoverHeading(e?.target.textContent);
  };

  const handleMouseLeaveOne = (e) => {
    setAboutItemOneHover(false);
    setHoverHeading(null);
    console.log("leave");
  };

  const handleMouseEnterTwo = (e) => {
    setAboutItemTwoHover(true);
    setHoverHeading(e?.target.textContent);
  };

  const handleMouseLeaveTwo = () => {
    setAboutItemTwoHover(false);
    console.log("leave");
    setHoverHeading(null);
  };

  const handleMouseEnterThree = (e) => {
    setAboutItemThreeHover(true);
    setHoverHeading(e?.target.textContent);
  };

  const handleMouseLeaveThree = () => {
    setAboutItemThreeHover(false);
    console.log("leave");
    setHoverHeading(null);
  };

  const handleMouseProductEnterOne = (e) => {
    setProductItemOneHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseProductLeaveOne = () => {
    setProductItemOneHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseProductEnterTwo = (e) => {
    setProductItemTwoHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseProductLeaveTwo = () => {
    setProductItemTwoHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseProductEnterThree = (e) => {
    setProductItemThreeHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseProductLeaveThree = () => {
    setProductItemThreeHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseServiceEnterOne = (e) => {
    setServiceItemOneHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseServiceLeaveOne = () => {
    setServiceItemOneHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseServiceEnterTwo = (e) => {
    setTurnkeyHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseServiceLeaveTwo = () => {
    setTurnkeyHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseServiceEnterThree = (e) => {
    setServiceItemThreeHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseServiceLeaveThree = () => {
    setServiceItemThreeHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseServiceEnterFour = (e) => {
    setServiceItemFourHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseServiceLeaveFour = () => {
    setServiceItemFourHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseServiceEnterFive = (e) => {
    setServiceItemFiveHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseServiceLeaveFive = () => {
    setServiceItemFiveHover(false);
    setHoverHeading(null);

    console.log("leave");
  };
  const handleMouseEnterContact = (e) => {
    setContactItemMainHover(true);
    setHoverHeading(e?.target.textContent);
  };
  const handleMouseLeaveContact = (e) => {
    setContactItemMainHover(false);
    setHoverHeading(null);
  };
  const handleMouseContactEnterOne = (e) => {
    setHoverHeading(e?.target.textContent);

    setContactItemOneHover(true);
    console.log("enter");
  };

  const handleMouseContactLeaveOne = () => {
    setContactItemOneHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseContactEnterTwo = (e) => {
    setContactItemTwoHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseContactLeaveTwo = () => {
    setContactItemTwoHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseContactEnterThree = (e) => {
    setServiceItemThreeHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseContactLeaveThree = () => {
    setServiceItemThreeHover(false);

    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseContactEnterFour = (e) => {
    setContactItemFourHover(true);
    setHoverHeading(e?.target.textContent);

    console.log("enter");
  };

  const handleMouseContactLeaveFour = () => {
    setContactItemFourHover(false);
    setHoverHeading(null);

    console.log("leave");
  };

  const handleMouseEnterColop = (e) => {
    setHoverHeading(e?.target?.textContent);
    setColopHover(true);
  };

  const handleMouseLeaveColop = (e) => {
    setHoverHeading(null);
    setColopHover(false);
  };

  const handleMouseEnterPlanet = (e) => {
    setHoverHeading(e?.target?.textContent);
    setPlanetHover(true);
  };
  const handleMouseLeavePlanet = (e) => {
    setHoverHeading(null);
    setPlanetHover(false);
  };

  const handleMouseServiceEnterConsultaion = (e) => {
    setHoverHeading(e?.target?.textContent);
    setConsultationHover(true);
  };
  const handleMouseServiceLeaveConsultation = (e) => {
    setHoverHeading(null);
    setConsultationHover(false);
  };

  const handleMouseEnterTelecom = (e) => {
    setHoverHeading(e?.target?.textContent);
    setTelecomHover(true);
  };
  const handleMouseLeaveTelecom = () => {
    setHoverHeading(null);
    setTelecomHover(false);
  };
  const aboutInfoData = {
    dataAboutMain:
      "We at METANOW specialize in innovation and digital transformation, and we firmly believe that our efforts can change the world. We work with individuals and companies who feel the same way, and we push boundaries to find new solutions focus on what matters. We're the leading force in this field, and we're here to help you succeed. We turn your current assets into miracles.",
    dataCEO:
      "Fear less, dominate more, build in silence, march with confidence, don't avoid the storm BE THE STORM",
    dataBlog:
      "Stay up to date with the latest industry news and trends by checking out our blogs and events page. We regularly update our blog with informative and insightful articles that cover a wide range of topics related to digital transformation, AI, IoT, and more.",

    dataMissionVision:
      "Discover the reason behind our success and how we can help you achieve yours.",
    dataMeetTeamMeta:
      "METANOW is a digital transformation solution company that was founded by two entrepreneurs - the CEO and the CTO - who are passionate about the digital era. Our company is dedicated to helping businesses grow and reach their full potential in the digital age. We offer comprehensive solutions that integrate products with services using AI and IoT technologies. We believe that our team is our greatest asset, and we strive to create a positive and productive work environment that fosters creativity, innovation, and collaboration.",
    dataProductMain:
      "Designed to simplify your life and enhance your productivity. Our smart products are a game changer.",
    dataExclusiveProducts:
      "Experience the best crafted with the highest quality materials and the latest technology. With our exclusive products we are a cut above the rest.",
    dataIctMain:
      "Empower your business from networking to security to software, we have the tools you need to succeed in today's digital era",
    dataIot:
      "Transform your business to optimize your operations and enhance your customer experience",
    dataServiceMain:
      "Our comprehensive range of services is tailored to meet your unique needs from installation to maintenance to training.",
    dataIntegration:
      "Integrate your systems and streamline your operations with our expert integration services.",
    dataProfessionalServices:
      "By partnering with a trusted digital transformation solution provider, businesses can unlock the full potential to thrive in the digital age and achieve long-term success.",
    dataConsultation:
      "At METANOW, we believe that consultation is key to successful digital transformation. We offer consultation services that are designed to help businesses develop and implement effective digital transformation strategies. Our experts can help businesses identify their goals and develop a roadmap that will help them reach their full potential.",
    dataTurnKey:
      `Our turnkey solutions are designed to help businesses transform and grow quickly and efficiently. We offer comprehensive solutions that are tailored to our clients specific needs and goals. From product development to implementation, we can help businesses take their operations to the next level.`,

    dataContactMain:
      "If you're interested in learning more about how METANOW can help your business transform and reach its full potential, please contact us today. Our team of experts is always available to answer your questions and provide you with the information you need to make the best possible decision for your business.      ",
    dataSeventeen:
      "Need help? Our support team is here to assist you. Fill out our support form and we'll get back to you as soon as possible.",
    dataLiveChat:
      "Our dedicated team is ready to assist your questions or concerns you may have.",
    dataSocialMedia:
      "Follow us and stay up-to-date with the latest news, promotions, and updates, and let us hear your voices.",
    dataVuzix:
      "An industry leader in Augmented Reality (AR) technology, we provide innovative optical products to defence, security and enterprise clients.",
    dataColop:
      "With its advanced blockchain technology, the Colop e-mark secure provides a highly secure and tamper-proof way to verify the authenticity of documents. Ensuring that important information remains accurate and reliable.",
    dataPlanet:
      "The ASTRO PLANET 5G slider mobile represents a significant transformation in mobile device design, providing a seamless and immersive experience for users.",
    dataNetworking:
      "Effective networking solutions can help businesses and organizations improve communication, collaboration, and productivity, enabling them to stay competitive and meet the ever-changing demands of the modern business landscape.",
    dataSecurity:
      "With the right cyber security measures in place, you can protect your sensitive information, maintain business continuity, and gain a competitive edge by demonstrating a commitment to security and trust to your customers and stakeholders.",
    dataTelecommunication:
      "At METANOW we offer comprehensive networking and security solutions to maximize your business operations and protect your valuable assets. Our innovative and cost-effective solutions are designed to enable businesses of all sizes to stay connected, efficient, and secure.",
    dataCto: `بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ :- وَمَنْ يَتَّقِ اللَّهَ يَجْعَلْ لَهُ مَخْرَجًا وَيَرْزُقْهُ مِنْ حَيْثُ لَا يَحْتَسِبُ ۚ وَمَنْ يَتَوَكَّلْ عَلَى اللَّهِ فَهُوَ حَسْبُهُ ۚإِنَّ اللَّهَ بَالِغُ أَمْرِهِ ۚ قَدْ جَعَلَ اللَّهُ لِكُلِّ شَيْءٍ قَدْرًا صَدَقَ اللّٰهُ الْعَظِيْم\nWhoever protects himself from Allah, He will open a way out for him. And He will provide sustenance for him from where he does not expect.\nHe who places his trust in Allah, Allah will be sufficient for him Indeed, Allah will fulfill His word!\n`,
    dataAi:
      "Experience the future of technology with our AI solutions. Our advanced AI technology is designed to revolutionize the way you do business.",
  };

  const navToSlider = () => {
    const element = document.getElementById("horizontalSlider");

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      history.push("/");
      const element = document.getElementById("horizontalSlider");

      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navToVisionSection = () => {
    const element = document.getElementById("visionSection");

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      history.push("/");
      const element = document.getElementById("visionSection");

      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">HOME</Link>
      </li>

      <li
        className="with-megamenu"
        onMouseOver={reduceOpacity}
        onMouseLeave={defaultOpacity}
      >
        <Link
          to="#"
          onMouseOver={handleMouseEnterAbout}
          onMouseLeave={handleMouseLeaveAbout}
        >
          ABOUT US
        </Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className=" megaMenuItemsContainer">
              <div
                className="megaMenudescriptionContainer"
                style={{ width: "40%" }}
              >
                <h6>{hoverHeading !== null ? hoverHeading : "ABOUT US"}</h6>
                {/* <p>{AboutItemOneHover?aboutInfoData?.dataAboutMain:AboutItemTwoHover?aboutInfoData?.dataTwo:AboutItemThreeHover?aboutInfoData?.dataThree:'When you will hover, the description will change dynamically'}</p> */}

                <p className="navContentPara">
                  {AboutItemMainHover ? (
                    aboutInfoData?.dataAboutMain
                  ) : subItemTwoHover ? (
                    <pre className="navContentPara">
                      {aboutInfoData?.dataCto}
                    </pre>
                  ) : AboutItemOneHover ? (
                    aboutInfoData?.dataMissionVision
                  ) : AboutItemTwoHover ? (
                    aboutInfoData?.dataMeetTeamMeta
                  ) : subItemOneHover ? (
                    aboutInfoData?.dataCEO
                  ) : AboutItemThreeHover ? (
                    aboutInfoData?.dataBlog
                  ) : (
                    aboutInfoData?.dataAboutMain
                  )}
                </p>
              </div>
              <div></div>
              <div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseEnter={handleMouseEnterOne}
                    onMouseLeave={handleMouseLeaveOne}
                    onClick={navToVisionSection}
                  >
                    VISION & MISSION
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item    align-items-center">
                  <li className="has-droupdown">
                    <Link
                      // to={"/"}
                      className="megaMenuLink "
                      onMouseOver={handleMouseEnterTwo}
                      onMouseLeave={handleMouseLeaveTwo}
                    >
                      MEET TEAMETA
                      <AiOutlineArrowRight className="navListIcon " />
                    </Link>
                    <ul className="submenu">
                      <li className="nav-list-sub-item">
                        <Link
                          className="megaMenuLink "
                          to="/ceo"
                          onMouseOver={handleMouseEnterSubOne}
                          onMouseLeave={handleMouseLeaveSubOne}
                        >
                          CHIEF EXECUTIVE OFFICER
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                      <li className="nav-list-sub-item">
                        <Link
                          onMouseOver={handleMouseEnterSubTwo}
                          onMouseLeave={handleMouseLeaveSubTwo}
                          className="megaMenuLink "
                          to="/cto"
                        >
                          CHIEF TECHNICAL OFFICER
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                    </ul>
                  </li>
                  {/* <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  /> */}
                </div>

                <div
                  className="nav-item nav-list-item   d-flex align-items-center"
                  // style={{ marginBottom: "100px" }}
                >
                  <Link
                    className="megaMenuLink "
                    onMouseOver={handleMouseEnterThree}
                    onMouseLeave={handleMouseLeaveThree}
                  >
                    BLOGS & EVENTS{" "}
                    <span className="rn-badge-card">(COMING SOON)</span>
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li
        className="with-megamenu"
        onMouseOver={reduceOpacity}
        onMouseLeave={defaultOpacity}
      >
        <Link
          to="#"
          onMouseOver={handleMouseEnterProduct}
          onMouseLeave={handleMouseLeaveProduct}
        >
          PRODUCTS
        </Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className=" megaMenuItemsContainer">
              <div
                className="megaMenudescriptionContainer"
                style={{ width: "40%" }}
              >
                <h6>{hoverHeading !== null ? hoverHeading : "PRODUCTS"}</h6>
                {/* <p>{AboutItemOneHover?aboutInfoData?.dataAboutMain:AboutItemTwoHover?aboutInfoData?.dataTwo:AboutItemThreeHover?aboutInfoData?.dataThree:'When you will hover, the description will change dynamically'}</p> */}

                <p>
                  {ProductItemMainHover
                    ? aboutInfoData?.dataProductMain
                    : colopHover
                    ? aboutInfoData?.dataColop
                    : planetHover
                    ? aboutInfoData?.dataPlanet
                    : ictSubItemOneHover
                    ? aboutInfoData.dataNetworking
                    : ictSubItemThreeHover
                    ? aboutInfoData.dataVuzix
                    : telecomHover
                    ? aboutInfoData?.dataTelecommunication
                    : ictSubItemTwoHover
                    ? aboutInfoData.dataSecurity
                    : ProductItemOneHover
                    ? aboutInfoData?.dataExclusiveProducts
                    : ProductItemTwoHover
                    ? aboutInfoData?.dataIctMain
                    : ProductItemThreeHover
                    ? aboutInfoData?.dataIot
                    : aboutInfoData?.dataProductMain}
                </p>
              </div>
              <div></div>
              <div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <li className="has-droupdown">
                    <Link
                      className="megaMenuLink"
                      onMouseEnter={handleMouseProductEnterOne}
                      onMouseLeave={handleMouseProductLeaveOne}
                      onClick={navToSlider}
                    >
                      EXCLUSIVE PRODUCTS
                      <AiOutlineArrowRight
                        // strokeWidth={"99px"}
                        // fontSize={'90px'}
                        className="navListIcon "
                      />
                    </Link>
                    <ul className="submenu">
                      <li className="nav-list-sub-item">
                        <Link
                          onMouseOver={handleMouseEnterICTSubThree}
                          onMouseLeave={handleMouseLeaveICTSubThree}
                          to="/vuzix"
                          className="megaMenuLink "
                        >
                          VUZIX-VIEW THE FUTURE
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                      <li className="nav-list-sub-item">
                        <Link
                          onMouseOver={handleMouseEnterPlanet}
                          onMouseLeave={handleMouseLeavePlanet}
                          className="megaMenuLink "
                          to={"/astro-planet"}
                        >
                          ASTRO PLANET
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                      <li className="nav-list-sub-item">
                        <Link
                          className="megaMenuLink "
                          onMouseOver={handleMouseEnterColop}
                          onMouseLeave={handleMouseLeaveColop}
                          to={"/colop"}
                        >
                          COLOP E-MARK SECURE
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                    </ul>
                  </li>
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <li className=" has-droupdown ">
                    <Link
                      className="megaMenuLink "
                      onMouseOver={handleMouseProductEnterTwo}
                      onMouseLeave={handleMouseProductLeaveTwo}
                    >
                      INFORMATION COMMUNICATION TECHNOLOGY
                      <AiOutlineArrowRight
                        // strokeWidth={"99px"}
                        // fontSize={'90px'}
                        className="navListIcon "
                      />
                    </Link>
                    <ul className="submenu">
                      <li className="nav-list-sub-item">
                        <Link
                          className="megaMenuLink "
                          to="/networking-solutions"
                          onMouseOver={handleMouseEnterICTSubOne}
                          onMouseLeave={handleMouseLeaveICTSubOne}
                        >
                          NETWORKING
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                      <li className="nav-list-sub-item">
                        <Link
                          onMouseOver={handleMouseEnterICTSubTwo}
                          onMouseLeave={handleMouseLeaveICTSubTwo}
                          className="megaMenuLink "
                          to="/security-services"
                        >
                          CYBER SECURITY
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                      <li className="nav-list-sub-item">
                        <Link
                          onMouseOver={handleMouseEnterTelecom}
                          onMouseLeave={handleMouseLeaveTelecom}
                          className="megaMenuLink "
                          to="/telecommunication-services"
                        >
                          TELECOMMUNICATION
                        </Link>
                        <AiOutlineArrowRight className="navListSubIcon " />
                      </li>
                    </ul>
                  </li>
                </div>
                <div
                  className="nav-item nav-list-item  d-flex align-items-center"
                  // style={{ marginBottom: "100px" }}
                >
                  <Link
                    to={"/iot"}
                    className="megaMenuLink "
                    onMouseOver={handleMouseProductEnterThree}
                    onMouseLeave={handleMouseProductLeaveThree}
                  >
                    INTERNET OF THINGS
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li
        className="with-megamenu"
        onMouseOver={reduceOpacity}
        onMouseLeave={defaultOpacity}
      >
        <Link
          to="#"
          onMouseOver={handleMouseEnterService}
          onMouseLeave={handleMouseLeaveService}
        >
          SERVICES
        </Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className=" megaMenuItemsContainer">
              <div
                className="megaMenudescriptionContainer"
                style={{ width: "40%" }}
              >
                <h6>{hoverHeading !== null ? hoverHeading : "SERVICES"}</h6>
                {/* <p>{AboutItemOneHover?aboutInfoData?.dataAboutMain:AboutItemTwoHover?aboutInfoData?.dataTwo:AboutItemThreeHover?aboutInfoData?.dataThree:'When you will hover, the description will change dynamically'}</p> */}

                <p>
                  {ServiceItemMainHover
                    ? aboutInfoData?.dataServiceMain
                    : ServiceItemOneHover
                    ? aboutInfoData?.dataIntegration
                    : ServiceItemTwoHover
                    ? aboutInfoData?.dataProfessionalServices
                    : consultationHover
                    ? aboutInfoData?.dataConsultation
                    : ServiceItemThreeHover
                    ? aboutInfoData?.dataAi
                    : ServiceItemFiveHover
                    ? aboutInfoData?.dataProfessionalServices
                    : ServiceItemFourHover
                    ? aboutInfoData.dataFourteen
                    : turnkeyHover
                    ? aboutInfoData?.dataTurnKey
                    : aboutInfoData?.dataServiceMain}
                </p>
              </div>
              <div></div>
              <div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseEnter={handleMouseServiceEnterOne}
                    onMouseLeave={handleMouseServiceLeaveOne}
                    to="/integration"
                  >
                    INTEGRATION{" "}
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseOver={handleMouseServiceEnterConsultaion}
                    onMouseLeave={handleMouseServiceLeaveConsultation}
                    to={"/consultation"}
                  >
                    CONSULTATION{" "}
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    to={"/ai-technology"}
                    className="megaMenuLink "
                    onMouseOver={handleMouseServiceEnterThree}
                    onMouseLeave={handleMouseServiceLeaveThree}
                  >
                    AI TECHNOLOGY
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseOver={handleMouseServiceEnterTwo}
                    onMouseLeave={handleMouseServiceLeaveTwo}
                    to={"/turn-key-solutions"}
                  >
                    TURNKEY SOLUTIONS{" "}
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseOver={handleMouseServiceEnterFive}
                    onMouseLeave={handleMouseServiceLeaveFive}
                    to={"professional-services"}
                  >
                    PROFESSIONAL SERVICES{" "}
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li
        className="with-megamenu"
        onMouseOver={reduceOpacity}
        onMouseLeave={defaultOpacity}
      >
        <Link
          to={"/contact"}
          onMouseOver={handleMouseEnterContact}
          onMouseLeave={handleMouseLeaveContact}
        >
          CONTACT US
        </Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className=" megaMenuItemsContainer">
              <div
                className="megaMenudescriptionContainer"
                style={{ width: "40%" }}
              >
                <h6>{hoverHeading !== null ? hoverHeading : "CONTACT US"}</h6>
                {/* <p>{AboutItemOneHover?aboutInfoData?.dataAboutMain:AboutItemTwoHover?aboutInfoData?.dataTwo:AboutItemThreeHover?aboutInfoData?.dataThree:'When you will hover, the description will change dynamically'}</p> */}

                <p>
                  {ContactItemMainHover
                    ? aboutInfoData?.dataContactMain
                    : ContactItemOneHover
                    ? aboutInfoData?.dataSeventeen
                    : ContactItemTwoHover
                    ? aboutInfoData?.dataLiveChat
                    : ServiceItemThreeHover
                    ? aboutInfoData?.dataSocialMedia
                    : aboutInfoData?.dataContactMain}
                </p>
              </div>
              <div></div>
              <div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseEnter={handleMouseContactEnterOne}
                    onMouseLeave={handleMouseContactLeaveOne}
                    to="/contact"
                  >
                    SUPPORT FORM
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseOver={handleMouseContactEnterThree}
                    onMouseLeave={handleMouseContactLeaveThree}
                  >
                    SOCIAL MEDIA
                    <span className="rn-badge-card"> (PUBLISH SOON)</span>
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
                <div className="nav-item nav-list-item  d-flex align-items-center">
                  <Link
                    className="megaMenuLink"
                    onMouseOver={handleMouseContactEnterTwo}
                    onMouseLeave={handleMouseContactLeaveTwo}
                  >
                    LIVE CHAT
                    <span className="rn-badge-card"> (COMING SOON)</span>
                  </Link>
                  <AiOutlineArrowRight
                    // strokeWidth={"99px"}
                    // fontSize={'90px'}
                    className="navListIcon "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};
export default Nav;
