import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div id="visionSection" className="row service-wrapper aboutSection">
        {/* <div className="col-lg-12"></div> */}
        <div className="col-lg-6 col-sm-12 " data-aos="fade-left">
          <SectionTitle
            textAlign="text-center"
            radiusRounded=""
            // subtitle="What we can do for you"
            title="OUR VISION"
            // description="With a team of veteran developers, Code Encoders offer a wide spectrum of IT solutions, mainly Blockchain development, Web application development and Landing pages, Mobile application development and much more. We build client relationship by providing them quality CODE."
          />
          <p className="aboutText">
            Our vision is to be the trusted partner of choice for businesses
            seeking to transform digitally. We lead the industry in
            delivering innovative and effective solutions to drive growth and
            success for our clients.
          </p>

          <p className="aboutText">
            We believe in a future where technology is a seamless part of
            everyday life, enabling businesses to operate more efficiently,
            effectively, and securely. Our goal is to shape the future and
            make a positive impact on the world through technology.
          </p>
        </div>
        <div className="col-lg-6  col-sm-12" data-aos="fade-right">
          <SectionTitle
            textAlign="text-center"
            radiusRounded=""
            // subtitle="What we can do for you"
            title="OUR MISSION"
            // description="With a team of veteran developers, Code Encoders offer a wide spectrum of IT solutions, mainly Blockchain development, Web application development and Landing pages, Mobile application development and much more. We build client relationship by providing them quality CODE."
          />
          <p className="aboutText">
            Our mission is empowering businesses to transform
            digitally and reach their full potential. We believe that technology
            should serve as a catalyst for both growth and innovation, and we're
            dedicated to tailor solutions that help our clients succeed.
          </p>
          <p className="aboutText">
            Our commitment to excellence, combined with our expertise and
            experience, sets us apart in the industry. We're passionate about
            helping our clients navigate the complex world of technology and
            achieve their goals.
          </p>
        </div>

        <video autoPlay loop muted playsInline className="aboutBgVideo">
          <source src="/images/vids/bgVideoTwo.mp4" type="video/mp4" />
        </video>
      </div>
    </ScrollAnimation>
  );
};
export default ServiceOne;
