import React from "react";
import SEO from "../../common/SEO";

import CalltoActionOne from "./CalltoActionOne";
import CalltoActionTwo from "./CalltoActionTwo";

import Separator from "../separator/Separator";

const CalltoAction = () => {
  return (
    <>
      <SEO title="Call To Action || Metanow - React Business  Template" />

      <div className="main-content">
        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area ">
          <div className="wrapper">
            <div className="row"></div>
            <CalltoActionOne />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area ">
          <div className="wrapper">
            <div className="row"></div>
            <CalltoActionTwo />
          </div>
        </div>

        {/* End Elements Area  */}

        <Separator />
      </div>
    </>
  );
};
export default CalltoAction;
