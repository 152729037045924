import Layout from "../../common/Layout";
import PageBanner from "../../common/page-banner/PageBanner";

import IotContent from "./IotContent";
import SectionTitle from "../sectionTitle/SectionTitle";
import TabTwo from "../tab/TabTwo";

import Separator from "../separator/Separator";
import IotPortfolio from "./IotPortfolio";
import SEO from "../../common/SEO";

const Iot = () => {
  return (
    <>
    <SEO title={'INTERNET OF THINGS'}/>
      <Layout>
        <PageBanner
          pageTitle="INTERNET OF THINGS"
          homePageUrl="/"
          homePageText="Products"
          activePageText="INTERNET OF THINGS"
          bgImgClass="item-bg4"
        />
        <IotContent />
        {/* <Team /> */}
        {/* <PartnerContent />
                <FeedbackSlider />
                <PricingCard />
                <FreeTrialForm /> */}

        {/* start tab area */}

        <div className="rwt-tab-area rn-section-gap ">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="You can Change Easily"
                  title="FUNDAMENTAL LAYERS OF THE INTERNET OF THINGS (IOT)"
                  description="The Perception, Network, and Application layers are the three fundamental layers of the Internet of Things (loT) technology, which together enable the seamless communication, connectivity, and interoperability between various devices, systems, and applications in the loT ecosystem."
                />
              </div>
            </div>
            <TabTwo />
          </div>
        </div>

        {/* end tab area */}

        {/* Start Service Area */}
        {/* <Service/> */}

        <IotPortfolio />
        <Separator />

        {/* End Service Area */}
      </Layout>
    </>
  );
};

export default Iot;
