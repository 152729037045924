import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: '2',
        name: 'Capt. Abdullah AL Shamsi',

        location: 'CO Miego, AD, USA',
        description: 'Their technology is truly game-changing, and their team is a pleasure to work with. They are constantly innovating and improving, ensuring that we always have access to the latest solutions. thrilled to have found such a fantastic partner for our operations.',

    },
    {
        image: '1',
        name: 'Mr. Mohammed Al Sarkal',

        location: 'Bangladesh',
        description: 'Their technology is simply topclass, and their support is unmatched. they understood our unique needs and tailored their solutions to fit, Working with METANOW has been an absolute pleasure.',

    },
    {
        image: '3',
        name: 'Eng. Mohammed Kharbatli',

        location: 'Poland',
        description: 'Their unique & innovative solutions made the implementation process a breeze. Their team is always available to support & guide. an unmatched performance by METANOW.',
    },
]


const TestimonialOne = ({ column, teamStyle }) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`/images/testimonial/${data.image}.webp`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title testimonialName capitalText">{data.name}</h2>
                                    <p></p>
                                    <p className="description">{data.description}</p>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;