import React from "react";
import Layout from "../common/Layout";

import PageBanner from "../common/page-banner/PageBanner";
import SEO from "../common/SEO";

const Vuzix = ({ data }) => {
  return (
    <>
    <SEO title={'VUZIX'}/>
      <Layout>
        <PageBanner
          pageTitle=" VUZIX-VIEW THE FUTURE"
          homePageUrl="/"
          homePageText="HOME"
          activePageText="VUZIX-VIEW THE FUTURE"
          bgImgClass="vuzix"
        />

        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <h3 className="theme-gradient">VUZIX BLADE 2.0</h3>
                  <p data-aos="fade-right">
                    The Vuzix Blade are the first Augmented Reality (AR) Smart
                    Glasses featuring advanced waveguide optics for hands-free
                    mobile computing & connectivity. Augmented Reality Smart
                    Glasses
                  </p>

                  <p data-aos="fade-right">
                    The Vuzix Blade is the perfect workplace tool for delivering
                    enhanced functionality for your hands-free, mobile computing
                    requirements. Ideal for light business mobile applications –
                    from accessing real-time data, to work instructions, to
                    light augmented reality, to HD photography and video
                    recording. The perfect mobile communications tool for daily
                    use in your business activities.
                  </p>

                  <p data-aos="fade-right">
                    The Vuzix Blade Smart Glasses are completely untethered, and
                    support both Wi-Fi and Bluetooth connectivity. And they
                    deliver a “hands free” connection of the digital world to
                    the Real World, providing unprecedented access to
                    location-aware connected information, and more. The private,
                    see thru, full color, bright, high resolution display screen
                    performs both indoors or in sunlight. The Vuzix Blade – is
                    your very own wearable, connection to your digital life.
                  </p>
                  <h3 className="theme-gradient">TECHNICAL SPECIFICATIONS</h3>
                  <h4 className="capitalText">Optics and Electronics</h4>
                  <ul data-aos="fade-left">
                    <li> Waveguide based see through optics</li>
                    <li> Vibrant full color display</li>
                    <li> Right eye monocular</li>
                    <li> Quad Core ARM CPU</li>
                    <li> 8 Megapixel camera (with auto-focus**)</li>
                    <li> Android OS</li>
                  </ul>
                  <h4 className="capitalText">Controls</h4>
                  <ul data-aos="fade-left">
                    <li>Voice control – multilingual</li>
                    <li> Touch pad with gesture</li>
                    <li> Head motion trackers</li>
                    <li>Haptic vibration alerts</li>
                    <li> Companion app for Android & iOS devices</li>
                  </ul>

                  <h4 className="capitalText">Audio</h4>
                  <ul data-aos="fade-left">
                    <li>Micro USB ear-phone jack</li>
                    <li>Full BT functionality</li>
                    <li>Noise canceling microphones</li>
                    <li>Built-in audio</li>
                  </ul>

                  <h4 className="capitalText">Battery</h4>
                  <ul data-aos="fade-left">
                    <li>Internal LiPo rechargeable batteries</li>
                  </ul>

                  <h4 className="capitalText">
                    Versatile Eyeglass Options Available{" "}
                  </h4>
                  <ul data-aos="fade-left">
                    <li> Prescription inserts</li>
                    <li> All lenses standard with UV protection</li>
                  </ul>
                  <h4 className="capitalText">Connectivity</h4>
                  <ul data-aos="fade-left">
                    <li> microSD expansion slot</li>
                    <li> 2.4GHz WiFi and Bluetooth wireless</li>
                    <li> Micro USB</li>
                  </ul>

                  <h3 className="captialText">
                    Blade Provides Total Functionality
                  </h3>

                  <ul data-aos="fade-left">
                    <li>
                      Loaded with features that help you stay connected and
                      active when on the go
                    </li>
                    <li>
                      {" "}
                      Take calls, check messages, listen to music while jogging,
                      hiking, walking, or exercising – all without touching your
                      phone
                    </li>
                    <li>
                      Text a friend, check appointments, take pictures – all
                      with voice commands
                    </li>
                    <li>
                      Enables location aware content providing access to
                      shopping data, travel routes, Yelp Scores and more
                    </li>
                    <li>
                      {" "}
                      Check and add content to Facebook and other social media
                    </li>
                    <li>Play games and watch videos</li>
                    <li>
                      Never miss an alert with the haptic feedback vibration and
                      then either touch the glasses or speak to respond
                    </li>
                    <li>Record life’s best moments with photos and videos</li>
                    <li> Calendar alerts to see invitations or what’s next</li>
                    <li>Daily assistant – see what and when you need it</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Vuzix;
