import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import AccordionOne from "./AccordionOne";
import Separator from "../separator/Separator";
import PageBanner from '../../common/page-banner/PageBanner';

const RnAccordion = () => {
    return (
        <>
            <SEO title="FAQS || Metanow" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Accordion"
                /> */}
                <div >
                    <PageBanner/>


                    {/* Start Accordion Area  */}
                    <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Accordion"
                                        title = "Faqs"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--35 row--20">
                                <div className="col-lg-10 offset-lg-1">
                                    <AccordionOne customStyle="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Separator />
                </div>
            </Layout>
        </>
    )
}
export default RnAccordion;