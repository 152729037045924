import React from "react";
import Layout from "../common/Layout";
import { FaSistrix } from "react-icons/fa";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";

const ComingSoon = () => {
  return (
    <>
    <SEO title='COMING SOON'/>
    <Layout>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h2 className="theme-gradient">COMING SOON</h2>

                <p className="description">
                  {" "}
                  Stay up to date with the latest industry news and trends by
                  checking out our blogs and events page. We regularly update
                  our blog with informative and insightful articles that cover a
                  wide range of topics related to digital transformation, AI,
                  IoT, and more.,
                </p>
               
                <div className="view-more-button">
                  <Link className="btn-default backToHomeBtn" to="/">
                    Go Back Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default ComingSoon;
